import { BiPen, BiSolidGroup, BiUser } from "react-icons/bi";

export const generateUserTypeIcon = (type)=> {

    switch (type) {
      case 0:
        return (
          <div id="userTypeIcon" className='userTypeIcon userTypeGeneral'>
            <BiUser/>
          </div>
        );
      case 1:
        return (
          <div id="userTypeIcon" className='userTypeIcon userTypeAuthor'>
            <BiPen/>
          </div>
        );
      case 2:
        return (
          <div id="userTypeIcon" className='userTypeIcon userTypeGroup'>
            <BiSolidGroup/>
          </div>
        )
    }
  }

  export const generateUserTypeIconOnly = (type)=> {

    switch (type) {
      case 0:
        return <BiUser/>
      case 1:
        return <BiPen/>
      case 2:
        return <BiSolidGroup/>
    }
  }