import React, { useState } from 'react'
import './SetCompass.css'
import { useDispatch, useSelector } from 'react-redux'
import Value from '../../ProfileLeft/Compass/Value/Value';
import { updateUser } from '../../../actions/UserAction';
import { BsFillInfoCircleFill, BsPlusLg } from 'react-icons/bs'
import { createValue } from '../../../actions/ValueAction';
import { TbCompass } from 'react-icons/tb';
import { getAllValues } from '../../../api/ValuesRequest';

const SetCompass = () => {
  
  const {user} = useSelector((state)=>state.authReducer.authData);
  const dispatch = useDispatch();
  
    // I need to sort out my error handling.  When I get a 400 response from the server,
    // there should be some kind of clean exit,
    // I don't want the reducer to be triggered, and I want form data to be set to the 
    // original empty object.  Neither is happening.
  const handleSubmit = (e) => {
    e.preventDefault(); 
    const formData = {
      value: document.getElementById("sc_dropdown").value,
      expound: document.getElementById("sc_expound_text").value
    }
    try {
      dispatch(createValue(user._id, formData));
      
    } catch (error) {
      console.log(error)
    }
    document.getElementById("sc_new_value_form").style.display = "none";
    document.getElementById("sc_default_option").selected = true;
    document.getElementById("sc_expound_text").value = '';
    // setFormData(newValueObj);
  }

  // Although I haven't gotten bit yet, I think this code is succeptible to a null pointer error on the dropdown container element.
  // It just happens to take longer to fetch the values each time than it does to render the element.
  const generateValueList = async () => {
    const res = await getAllValues();
    const data = res.data;
    console.log(data);
    var output = '<select id="sc_dropdown" name="value" class="sc-value-dropdown">' +
                    '<option id="sc_default_option" value="" selected disabled hidden>Choose a value to add to your Compass.</option>';
    for (let i = 0; i < data.length; i++) {
      var newString;
      switch (data[i].valueType) {
        case 0:
          newString = '<option disabled value="' + data[i]._id + '">Core Value: ' + data[i].value + '</option>';
          break;
        case 1:
          newString = '<option value="' + data[i].value + '">' + data[i].value + '</option>';
          break;
      }
        output += newString;
    }       
    output += '</select>'
    document.getElementById("sc_dropdown_container").innerHTML = output;
  }
  generateValueList();

  const displayForm = () => {
    const openFormEl = document.getElementById("sc_new_value_form");
    if (openFormEl.style.display === "none") {
      openFormEl.style.display = "block";
    } else {
      openFormEl.style.display = "none";
    }
  }

  return (
    <div className="SetCompass">
      <div className="sc-set-values">

        <h2 className='sc-title FlexRow'>
                <TbCompass/>
                Set Compass
        </h2>
        {user.compass.length > 0 
          ?   user.compass.map((value) => {
                  return <Value data={value} location="setCompass" /> 
              })
          :   <div>
                  You don't have any values set.  
                  Click the icon below to add your first value!
              </div>
        }
        <BsPlusLg onClick={displayForm}/> 
        
      </div>
      <div style={{display: "none"}} id="sc_new_value_form" className='sc-value-input-container'>
        <form action="">
          <div className='FlexColumn sc-input-form'>
              
              <div className="sc-dropdown-container" id="sc_dropdown_container"></div>
              
              <textarea
                  cols="30"
                  rows="5" 
                  type="text"
                  placeholder={"Tell the world a little more about why you think this value is so important and how you plan to judge items on North Star against this value."}
                  className='editInfoInput'
                  name='expound'
                  id="sc_expound_text"
              />
              
              <button className='button infoButton' onClick={handleSubmit}>Add New Value</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SetCompass