import { useDispatch } from 'react-redux';
import './EditProfile.css'
import React, { useState } from 'react'
import { uploadMedia } from '../../../actions/uploadAction';
import { updateUser } from '../../../actions/UserAction';
import { useParams } from 'react-router-dom';
import Croppie from 'croppie';
import { LiaTimesSolid } from 'react-icons/lia';
import { FaRotateLeft, FaRotateRight } from 'react-icons/fa6';
import { RiProfileLine } from 'react-icons/ri';


const EditProfile = ( {user, setProfileContent} ) => {
    
    const params = useParams();
    const dispatch = useDispatch();
    const {password, ...other} = user;
    const [formData, setFormData] = useState(other);
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);

          
    

    const handleChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onImageChange = (e) => {
      
      const handleCroppieX = () => {
        croppie.destroy();
        imageContainer.style.display = "none";
        uploadContainer.style.backgroundColor = "";
      }
      
      let uploadContainer, uploadLabel, imageContainer, tag;
      
      if (e.target.files && e.target.files[0]) {

        // Validate for file type
        const ext = e.target.files[0].type;
        const epValEl = document.getElementById("ep_validation");

        if (ext === 'image/jpg' || ext === 'image/jpeg' || ext === 'image/png') {
          
          epValEl.classList.add("General-hidden");

          e.target.name === "profileImage"
              ? tag = "profile"
              : tag = "cover"
          imageContainer = document.getElementById("croppie_image_holder_" + tag);      
          uploadContainer = document.getElementById("upload_media_container_" + tag);
          uploadContainer.style.backgroundColor = "var(--cardColor)";
          uploadLabel = document.getElementById("upload_" + tag + "_image");

          if (tag === "profile") {
            uploadLabel.innerText = "Profile Image";
          } else {
            uploadLabel.innerText = "Cover Image";
          }
          uploadLabel.style.cursor = "default";

          let img = e.target.files[0];
          imageContainer.style.display = "block";

          let el = document.getElementById('croppie_card_body_' + tag);
          
          // split this up based on target name for profile/cover
          if (e.target.name === "profileImage") {
            var croppie = new Croppie(el, {
                viewport: { width: 250, height: 250 },
                boundary: { width: 300, height: 300 },
                showZoomer: true,
                enableOrientation: true
            });
          } else {
            var croppie = new Croppie(el, {
              viewport: { width: 400, height: 175 },
              boundary: { width: 500, height: 500 },
              showZoomer: true,
              enableOrientation: true
          });
          }

          croppie.bind({
            url: URL.createObjectURL(img),
            orientation: 1
          });
          document.getElementById("croppie_rotate_left_" + tag).addEventListener("click", ()=>{
            console.log("Rotate Left 90")
            croppie.rotate(-90);
          })
          document.getElementById("croppie_rotate_right_" + tag).addEventListener("click", ()=>{
            console.log("Rotate Right 90")
            croppie.rotate(90);
          })
          document.getElementById("croppie_x_" + tag).addEventListener("click", handleCroppieX);
          
          document.getElementById("croppie_submit_" + tag).addEventListener("click", (event)=>{
            event.preventDefault();
            croppie.result('blob').then(function(blob) {
              const croppedImg = "<img src='" + URL.createObjectURL(blob) + "'>";
              // display cropped image
              const display = document.getElementById("croppie_cropped_display_" + tag);
              display.style.display = "block";
              display.innerHTML = croppedImg;

              // update FormData
              e.target.name === "profileImage"
                ? setProfileImage(blob)
                : setCoverImage(blob);

              // destroy croppie instance
              croppie.destroy();
              
              // clear and hide croppie image holder
              el.innerHTML = '';
              imageContainer.style.display = "none";

              // style container and original button  
              uploadContainer.style.backgroundColor = "";
              document.getElementById("upload_" + tag + "_image").style.backgroundColor = "var(--affirmativeGreen)";
            });
          });
        } else {
          epValEl.innerText = "Your image must be either a .jpg, .jpeg, or .png file.";
          epValEl.classList.remove("General-hidden");
        }
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      const epValEl = document.getElementById("ep_validation");
      console.log(formData.displayname);
      if (formData.displayname === "") {
        console.log("Inside displayname empty")
        epValEl.innerText = "You must have a Display Name.";
        epValEl.classList.remove("General-hidden");
            
      } else {
        let UserData = formData;
        var imageError = false;
        if (profileImage) {
          if (profileImage.size <= 1000000) {
            const data = new FormData();
            // Adjusted for croppie blob
            // const extension = profileImage.name.split('.').pop();
            // const fileName = user._id + "_profile_" + Date.now() + "." + extension;
            const fileName = user._id + "_profile_" + Date.now();
            data.append("name", fileName);
            data.append("file", profileImage);
            data.append("upload_preset", 'asx6uxrg');
            data.append("public_id", fileName);
            UserData.userImage = fileName + ".png";
            try {
              dispatch(uploadMedia(data))
            } catch (error) {
              console.log(error)
            }
          } else {
            epValEl.innerText = "Your cropped image filesize must be less than 1mb.";
            epValEl.classList.remove("General-hidden");
            imageError = true;
          }
        } 
        if (!imageError && coverImage) {
          if (coverImage.size <= 1000000) {
            const data = new FormData();
            // Adjusted for croppie blob
            // const extension = coverImage.name.split('.').pop();
            // const fileName = user._id + "_cover_" + Date.now() + "." + extension;
            const fileName = user._id + "_cover_" + Date.now();
            data.append("name", fileName);
            data.append("file", coverImage);
            data.append("upload_preset", 'asx6uxrg');
            data.append("public_id", fileName);
            UserData.userCover = fileName + ".png";
            try {
              dispatch(uploadMedia(data))
            } catch (error) {
              console.log(error)
            }
          } else {
            epValEl.innerText = "Your cropped image filesize must be less than 1mb.";
            epValEl.classList.remove("General-hidden");
            imageError = true;
          }
        }
        if (!imageError) {
          dispatch(updateUser(params.id, UserData));
          setProfileContent("card")
        }
      }
    }


    return (
        <div className="EditProfile">
          <form action="">
            <h2 className='ep-title FlexRow'>
              <RiProfileLine/>
              Edit Profile
            </h2>
            <div className='FlexColumn input-form'>
                <label className='ep-label'>Display Name:</label>
                <input 
                    type="text"
                    placeholder={user.displayname}
                    className='editInfoInput'
                    name='displayname'
                    onChange={handleChange}
                />
                <label className='ep-label'>Username:</label>
                <input 
                    type="text"
                    placeholder={"@" + user.username}
                    className='editInfoInput'
                    name='username'
                    disabled
                />
                <label className='ep-label'>Tagline:</label>
                <input 
                    type="text"
                    placeholder={user.tagline ? user.tagline : "Sum yourself up in a few words."}
                    className='editInfoInput'
                    name='tagline'
                    onChange={handleChange}
                />
                <label className='ep-label'>Personal Statement:</label>
                <textarea
                    cols="40"
                    rows="5" 
                    type="text"
                    placeholder={user.statement ? user.statement : "Describe yourself, your values, and what you're doing on NorthStar."}
                    className='editInfoInput'
                    name='statement'
                    onChange={handleChange}
                />
                
                <div className='ep-upload-media-container' id="upload_media_container_profile">
                  
                  <label className='ep-media-label' id="upload_profile_image">
                    <input type="file" name="profileImage" accept="image/png, image/gif, image/jpeg" onChange={onImageChange}/>
                    Upload Profile Image
                  </label>  

                  <div id="croppie_image_holder_profile" style={{display: "none"}}>
                    <div className="croppie-card-header">
                      Adjust image and select "Crop Image" when finished.
                    </div>
                    <div className="croppie-card-body" id="croppie_card_body_profile"></div>
                    <div className="croppie-card-footer FlexRow">
                      <FaRotateLeft id="croppie_rotate_left_profile" />
                      <FaRotateRight id="croppie_rotate_right_profile" />
                      <button className="button croppie-crop-image-button" id="croppie_submit_profile">Crop Image</button>
                      <LiaTimesSolid id="croppie_x_profile" />
                    </div>
                  </div>
                  <div id="croppie_cropped_display_profile" style={{display: "none"}}></div>
                  {/* <div id="profile_file_size"></div> */}

                </div>
                <div className='ep-upload-media-container' id="upload_media_container_cover">

                  <label className='ep-media-label' id="upload_cover_image">
                    <input type="file" name="coverImage" accept="image/png, image/gif, image/jpeg" onChange={onImageChange}/>
                    Upload Cover Image
                  </label>

                  <div id="croppie_image_holder_cover" style={{display: "none"}}>
                    <div className="croppie-card-header">
                    Adjust image and select "Crop Image" when finished.
                    </div>
                    <div className="croppie-card-body" id="croppie_card_body_cover"></div>
                    <div className="croppie-card-footer FlexRow">
                      <FaRotateLeft id="croppie_rotate_left_cover" />
                      <FaRotateRight id="croppie_rotate_right_cover" />
                      <button className="button croppie-crop-image-button" id="croppie_submit_cover">Crop Image</button>
                      <LiaTimesSolid id="croppie_x_cover" />
                    </div>
                  </div>
                  <div id="croppie_cropped_display_cover" style={{display: "none"}}></div>
                  {/* <div id="cover_file_size"></div> */}

                </div>
                
                <div id="ep_validation" className='validationError General-hidden'></div>
                
                <button className='button infoButton' onClick={handleSubmit}>Update Profile</button>

            </div>
          </form>
        </div>

    );
  }

export default EditProfile
