import { Element } from 'react-scroll';
import './LandingInvolved.css';

import React from 'react'
import { Link } from 'react-router-dom';
import { generateUserTypeIcon } from '../../../tools/userTypeConversion';

const LandingInvolved = () => {

    const scrollToAnchor = (location) => {
        const anchor = document.querySelector('#' + location);
        anchor.scrollIntoView({behavior: 'smooth', block: 'start'}) 
    }


  return (
    <div className="LandingInvolved">
        <Element id='anchor_involved' name='anchor_involved' ></Element>

        <div className='AppGeneralCard FlexColumn'>
            
            <h2 className='landing-aboutHeader'>Want to get involved?</h2>
            <span className='landing-involvedText'>
                <div>
                    North Star is completely free for all user types, and it's ready for you to jump in!  Go ahead and create an account here:
                </div>
                <div className="landing-buttonContainer">
                    <Link style={{textDecoration: "none", color: "inherit"}} to={'/auth'}>
                        <button className='button landing-signupButton'>
                        Sign Up/In
                        </button> 
                    </Link>
                </div>
                <div>
                    You'll choose a user type based on how you would like to use North Star:
                </div>
                <ol>
                    <li className='landing-involved-li'>{generateUserTypeIcon(0)}<b>General User:</b> Choose this user type if you intend to use North Star to learn more about the products, businesses, and people you interact with.</li>
                    <li className='landing-involved-li'>{generateUserTypeIcon(1)}<b>Author:</b> If you want to create content on North Star, this is the user type you want.  Check out the Author Initial Setup Walkthrough in the Resources section for a helpful document that explains exactly how to get started.</li>
                    <li className='landing-involved-li'>{generateUserTypeIcon(2)}<b>Group:</b> This user type is for organizations (or individuals) that advocate for a particular cause and have followers who look to them for help when navigating tricky moral situations.</li>
                </ol>
            </span>

            <h2 className='landing-aboutHeader'>Want to start a new Topic?</h2>
            <span className='landing-involvedText'>
                <div>
                    Do you want to use North Star as an Author or a Group but a Topic hasn't been started that quite fits your situation?  You've got some options.
                </div>
                
                <div>
                    <i>First, do you want to use North Star as an Author or a Group?</i>
                </div>
                <div className='landing-involved-innercard'>
                    <h4>Author</h4>
                    If you want to begin creating content about Topics that aren't active yet on North Star, there's nothing holding you back!  Go ahead and blaze a new path.  Authors are not restricted to writing on any Topic - the role of the Author is simply to create content that they believe is important, and support it with sources.  However, all of this content becomes useful when a Group stamps it and brings it to their followers.  So, while Authors can create content on any Topic, they will eventually want Groups involved who are also interested in the same Topic.  If you are aware of (or in contact with) Groups who share your enthusiasm for a Topic, you can work together to begin getting content created and stamped, and you'll be off to the races.<span className='landing-accented'>We will have a Resource available in the near future that will help to walk you through the steps to getting a new Topic started.</span>
                </div>
                <div className='landing-involved-innercard'>
                    <h4>Group</h4>
                    As a Group, your options depend on whether the specific Values that you intend to use for your account are present in North Star yet.  Do you see them in the Values description <span onClick={()=>scrollToAnchor("anchor_stats")}><u>above</u></span>?<br/><br/>
                    If the Values you intend to use are already being used on North Star, then there's nothing stopping you!  Connect with Authors and start stamping their data to bring your followers relevant content.  If there are no Authors writing about your specific Topic yet, work within your community to get people involved. <span className='landing-accented'>We will have a Resource available in the near future that will help to walk you through the steps to getting a new Topic started.</span><br/><br/>
                    If you don't see the Values you intend to use, then it's just because we haven't gotten to that Phase in the project yet.  <span className='landing-accented'>A Resource will also be posted in the near future that walks you through the process of contacting North Star to get new Values created so that you can start your new Topic.</span>
                </div>
            </span>
            
        </div>

        <div className='landing-backToTop' onClick={()=>scrollToAnchor("anchor_landing_top")}>Back to Top</div>
    </div>
  )
}

export default LandingInvolved