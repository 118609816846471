import { useSelector, useDispatch } from 'react-redux';
import './UserDetail.css'
import React, { useEffect } from 'react'
import { loadModal } from '../../actions/ModalAction';
import { generateUserTypeIcon } from '../../tools/userTypeConversion';
import UserStats from './UserStats';
import Value from '../ProfileLeft/Compass/Value/Value';
import { MdArrowForwardIos, MdArrowRightAlt } from 'react-icons/md';

const UserDetail = ( { data, userKey } ) => {

    const {user} = useSelector((state)=> state.authReducer.authData);
    
    
    const dispatch = useDispatch();
    const loadUser = () => {
        const modalPackage = {
            modalType: "user",
            data: data
        }  
        const logDetails = {
            activity: "view_user",
            data: {
                userId: user._id,
                username: user.username,
                viewed_userId: data._id,
                viewed_username: data.username
            },
            response: null, 
            status: null
          }  
        dispatch(loadModal(modalPackage, logDetails));
    }

    const actionCheck = (() => {
        // Assumes the appropriate userType only in the feed
        var actionType = null;
        switch (user.userType) {
          case 0:
            actionType = "follow";
            break;
          case 1:
            actionType = "subscribe";
            break;
          case 2:
            actionType = "subscribe";
            break;
        }
        if (actionType !== null) {
            const index = user[actionType].findIndex((id)=>id === data._id)
            if (index === -1) {
                return '';
            } else {
                return ' ud-follow-border';
            }
        }
      })()

      const handleStatementExpand = () => {
        document.getElementById(`ud_statement_container_${userKey}`).classList.toggle("ud-statement-mask");
        document.getElementById(`ud_statement_expand_${userKey}`).classList.toggle("General-exp-arrow-con");
      }

    // This may be an inappropriate use of UseEffect
    useEffect(()=>{
        const container = document.getElementById(`ud_statement_container_${userKey}`);
        if (container.clientHeight > 60) {
            container.classList.add("ud-statement-mask");
            document.getElementById(`ud_statement_expand_bar_${userKey}`).classList.remove("General-hidden");
        }
    })
    

  return (
    <div className={"UserDetail" + actionCheck}>
        <div className="ud-header" onClick={loadUser}>
            <img src={process.env.REACT_APP_PUBLIC_FOLDER + data.userImage} alt="" className='ud-user-img' />
            <div className="ud-headerDetails">
                <div className="UserName">
                    <span>{data.displayname}</span>
                    <span>@{data.username}</span>
                </div>
                <UserStats user={data} />
            </div>
            <div className="ud-typeIcon">
                {generateUserTypeIcon(data.userType)}
            </div>

        </div>
        <div className="ud-body-container">
            <span>
                "{data.tagline}"
            </span>
            <span id={`ud_statement_container_${userKey}`} className='ud-statement-container'>
                {data.statement}
            </span>
            <span id={`ud_statement_expand_bar_${userKey}`} className='ud-statement-expand-bar General-hidden' onClick={handleStatementExpand}>
                <MdArrowForwardIos id={`ud_statement_expand_${userKey}`} className='General-exp-arrow General-exp-arrow-con' />
            </span>
            {data.compass.length > 0 && 
                <span className='ud-values'>
                    {data.compass.map((value, valueKey) => {
                            return <Value data={value} location="userDetail" key={valueKey} id={userKey + "_" + valueKey} /> 
                        })
                    }
                </span>
            }
        </div>
        
    </div>
  )
}

export default UserDetail