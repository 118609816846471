import * as UserFeedApi from '../api/UserFeedRequest'

export const getUserFeed = (id, filters)=> async(dispatch)=> {
    dispatch({type: "USER_FEED_START"})
    try {
        const {data} = await UserFeedApi.getUserFeed(id, filters);
        dispatch({type: "USER_FEED_SUCCESS", data: data});
    } catch (error) {
        dispatch({type: "USER_FEED_FAIL"})
        console.log(error);
    }
}

export const getActionFeed = (id, action) => async(dispatch) => {
    // console.log("Inside getActionFeed")
    dispatch({type: "ACTION_FEED_START"})
    try {
        // console.log("This mean's it's hooked up right.")
        const {data} = await UserFeedApi.getActionFeed(id, action);
        // console.log(data)
        dispatch({type: "ACTION_FEED_SUCCESS", data: {data: data, action: action}});
    } catch (error) {
        dispatch({type: "ACTION_FEED_FAIL"});
        console.log(error);
    }
}