import React, { useState } from 'react'
import './DatumList.css'
import { FiBox } from 'react-icons/fi'
import { TbCompass } from 'react-icons/tb'
import { BiHash, BiPen } from 'react-icons/bi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { GiStamper } from 'react-icons/gi'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BsEye } from 'react-icons/bs'
import { loadModal } from '../../../../actions/ModalAction'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import { generateRelationIcon } from '../../../../tools/generateRelationIcon'
import Relation from '../../CreateDatum/AddRelationModal/Relation/Relation'


const DatumList = ({data, id}) => {

    const {user} = useSelector((state) => state.authReducer.authData)
    const [stamped, setStamped] = useState(data.stamps.includes(user._id))
    const [stamps, setStamps] = useState(data.stamps.length)
    const [viewed, setViewed] = useState(data.views.includes(user._id))
    const [views, setViews] = useState(data.views.length)
    // I'm trying this out below.  I can't decide if I need to generate the triggers count here or elsewhere
    // It will also be different for general and groups
    
    const userImgPath = process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage;

    // Style and add functionality for users viewing their own data
    // Should not be able to stamp or view, and they should appear a unique color (orange?)
    
    const datumContainerClass = () => {
        var classText = 'Datum';
        if (data.itemTriggered) {classText += ' triggeredDatum'}
        if (viewed) {classText += ' viewedDatum'}
        if (stamped) {classText += ' stampedDatum'}
        if (user.userType === 0) {classText += ' viewedDatum'}
        
        return classText;
    }

    const createdDate = () => {
        const date = new Date(data.createdAt);
        return (date.toDateString());
    }

    const followedGroupsBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="dl-followed-groups">
                    {data.stampDetails && data.stampDetails.map((stamp, id) => {
                        return <img key={id} src={process.env.REACT_APP_PUBLIC_FOLDER + stamp.groupImage}/>
                    })}
                </div> 
            </div>
        )
    }

    const authorThemeBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="datumDetails">
                    <div className="generalBar authorBar">
                        <BiPen/>
                        
                        <img src={userImgPath} alt="" className='authorBarImg' />
                        <span className="datumAuthor">
                            @{data.authorName}
                        </span>
                        <span className="timestamp">{createdDate()}</span>
                    </div> 
                    
                    {(data.themes.length > 0) && <div className="generalBar themesBar">
                        <BiHash/>
                        <span className="themes">
                            {data.themes.map((theme, _id) => {
                                return (
                                    <span id={`theme${_id}`} className='theme'>
                                        <a href='#'>
                                            #{theme}&nbsp;&nbsp; 
                                        </a>
                                    </span>
                                )
                            })}
                        </span>
                    </div>}
                </div> 
            </div>
        )
    }

    const getTriggers = () => {
        switch (user.userType) {
            case 0:
                let triggerCount = 0;
                for (let g = 0; g < data.stampDetails.length; g++) {
                    if (data.stampDetails[g].triggers) {
                        triggerCount += data.stampDetails[g].triggers.length;
                    } 
                }
                return triggerCount;
            
            case 2:
            const stampIndex = user.stamps.findIndex((stamp)=>stamp.datumId===data._id);
            if (stampIndex > -1) {
                return user.stamps[stampIndex].triggers.length
            } else {
                return 0;
            }
        }

    }
    const [triggers, setTriggers] = useState(getTriggers());

    const dispatch = useDispatch();
    const loadDatum = () => {

        const modalPackage = {
            modalType: "datum",
            data: data,
            stamped: stamped,
            setStamped: setStamped,
            stamps: stamps,
            setStamps: setStamps,
            viewed: viewed,
            setViewed: setViewed,
            views: views,
            setViews: setViews,
            triggers: triggers,
            setTriggers: setTriggers
        }

        const logDetails = {
            activity: "view_datum",
            data: {
                userId: user._id,
                username: user.username,
                datumId: data._id,
                datumText: data.datumText
            },
            response: null,
            status: null
        }

        dispatch(loadModal(modalPackage, logDetails));
    }

  return (
        <div 
            id={"datum_container_" + id} 
            className={datumContainerClass()}
            onClick={loadDatum}
        >
            <div className="datumBody">
                <div className="generalBar">
                    {/* <AiOutlineBarChart size="1rem"/> */}
                    {data.datumText !== '' && <span className='datumText'>
                        {data.datumText}
                    </span>}
                    {data.datumText === '' && <span className='dl-relations FlexColumn'>
                        {data.relations.map((r)=>{
                            return(
                                <div className="FlexRow dl-relationRow">
                                    <span className="dl-endIcon FlexRow">
                                        {generateRelationIcon(r.relation)}
                                    </span>
                                    <div className='dl-vl'></div>   
                                    <span className="dl-relation">
                                        <Relation 
                                            relation={r} 
                                            references={data.references}
                                        />
                                    </span>
                                </div>
                        )})}
                        
                    </span>}
                </div>
                <div className="generalBar attributes">
                    <FiBox />
                    <span className="referenced">{data.references.length}</span>
                    <HiArrowsRightLeft />
                    <span className="relations">{data.relations.length}</span>
                    <MdOutlineAssignmentTurnedIn/>
                    <span className="sources">{data.sources.length}</span>
                    <GiStamper style={stamped?{color: 'var(--primaryColor)'}:{color: 'gray'}}/>
                    <span className="stamps">
                        {stamps}
                    </span>
                    <BsEye style={viewed?{color: 'var(--primaryColor)'}:{color: 'gray'}}/>
                    <span className="views">
                        {views}
                    </span>
                    {user.userType !== 1
                        ? <>
                            <TbCompass className={triggers > 0 ? 'conflictIcon' : ''}/>
                            <span className="conflictCount">{triggers ? triggers : "0"}</span>
                        </>
                        : ""
                    }

                    {/* Here I'll put the followed group stamp tally for general users */}
                </div>
            </div>
            <hr/>

            {user.userType === 0
                ? followedGroupsBar()
                : authorThemeBar()
            }
            
        </div>
  )
}

export default DatumList