import React from 'react'
import './AddSourceModal.css'
import { LiaTimesSolid } from 'react-icons/lia'
import { BsUpload } from 'react-icons/bs'

const AddSourceModal = ( { setOpenASM, dataASM, setDataASM }) => {


    const handleSubmit = (e) => {
        e.preventDefault();
        var newSource = {};
        newSource.link = document.getElementById("asm_source_link").value;
        newSource.text = document.getElementById("asm_link_text").value;
        newSource.description = document.getElementById("asm_source_description").value;
        if (newSource.text === "" || newSource.link === "") {
            document.getElementById("cd_asm2_alert").innerText = "You must have a Source Link and Link Text for each source.";
            document.getElementById("cd_asm2_alert").classList.remove("General-hidden");
        } else {
            setDataASM(()=>{
                return [...dataASM, newSource]
            })
            console.log(dataASM);
            document.getElementById("asm_source_link").value = '';
            document.getElementById("asm_link_text").value = '';
            document.getElementById("asm_source_description").value = '';
            document.getElementById("cd_asm2_alert").classList.add("General-hidden");

        }
    }

    return (
      
        <div className="AddSourceModal FlexColumn">
            
            <div className="asm-header FlexRow">
                <span className="asm-header-text">Submit Source</span>
                <span className='asm-x' onClick={() => setOpenASM(false)}>
                    <LiaTimesSolid/>
                </span>
            </div>
            <div action="" className='Search FlexColumn asm-content'>
                <input className='AppInput' id="asm_source_link" type="text" placeholder="Source Link" />
                <input className="AppInput" id="asm_link_text" type="text" placeholder="Link Text" />
                <textarea 
                    cols="40"
                    rows="3"
                    id="asm_source_description"
                    placeholder='Source Description'
                    className='AppInput'
                />
                <div id="cd_asm2_alert" className='General-hidden General-validationError'></div>
                <button className="button signupButton" onClick={handleSubmit}>
                    <BsUpload />
                </button> 
            </div>
        </div>

    )
  }
  
  export default AddSourceModal