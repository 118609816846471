import React from 'react'
import './UserList.css'
import { generateUserTypeIcon } from '../../tools/userTypeConversion';
import { useDispatch, useSelector } from 'react-redux';
import { loadModal } from '../../actions/ModalAction';

const UserList = ( {data, id} ) => {

  const {user} = useSelector((state)=>state.authReducer.authData);

  const dispatch = useDispatch();
  const loadUser = () => {
    const modalPackage = {
      modalType: "user",
      data: data
    }
    const logDetails = {
      activity: "view_user",
      data: {
        userId: user._id,
        username: user.username,
        viewed_userId: data._id,
        viewed_username: data.username
      },
      response: null,
      status: null
    }
    dispatch(loadModal(modalPackage, logDetails));
  }

  return (
      <div className="User" onClick={loadUser}>        
        <div>
          <img src={process.env.REACT_APP_PUBLIC_FOLDER + data.userImage} alt="" className='UserImg' />
          <div className="UserName">
            <span>{data.displayname}</span>
            <span>@{data.username}</span>
          </div>
        </div>
        <div className="user-icons FlexColumn">
            <div className="user-typeIcon">
                {generateUserTypeIcon(data.userType)}
            </div>
            <div className="user-followIcon">
                {/* Not sure if I'll need this section, or how I'll use it. */}
            </div>
        </div>
            
      </div>
  )
}

export default UserList