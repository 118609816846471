import React, { useEffect } from 'react'
import './Home.css';
import ProfileSide from '../../components/ProfileSide/ProfileSide';
import Main from '../../components/Main/Main';
import TrendsSide from '../../components/TrendsSide/TrendsSide';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Modal, useMantineTheme } from '@mantine/core';
import { closeModalAction } from '../../actions/ModalAction';
import DatumView from '../../components/Main/Feed/Datum/DatumView';
import UserView from '../../components/User/UserView';
import ItemView from '../../components/Main/Feed/Item/ItemView';
import { Element } from 'react-scroll';

const Home = () => {

  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  const {modalData, rModalOpened} = useSelector((state)=>state.modalReducer);  
  const dispatch = useDispatch();

  useEffect(()=> {
    if (rModalOpened === true) {
      // console.log("rModalOpened", rModalOpened);
      open();
    }
  },[rModalOpened])
  
  const closeModal = () => {
    // console.log("Inside closeModal")
    // console.log("rModalOpened", rModalOpened);
    dispatch(closeModalAction());
    close();
  }

  const loadDatum = () => {
    return (
      <DatumView 
          data={modalData.data}
          stampedProp={modalData.stamped}
          setStampedProp={modalData.setStamped}
          stampsProp={modalData.stamps}
          setStampsProp={modalData.setStamps}
          viewedProp={modalData.viewed}
          setViewedProp={modalData.setViewed}
          viewsProp={modalData.views}
          setViewsProp={modalData.setViews}
      />
    )
  }

  const loadUser = () => {
    return (
      <UserView 
          data={modalData.data}
      />
    )
  }

  const modalSwitch = () => {
    switch (modalData.modalType) {
      case "datum":
        return (
          <DatumView 
              data={modalData.data}
              stampedProp={modalData.stamped}
              setStampedProp={modalData.setStamped}
              stampsProp={modalData.stamps}
              setStampsProp={modalData.setStamps}
              viewedProp={modalData.viewed}
              setViewedProp={modalData.setViewed}
              viewsProp={modalData.views}
              setViewsProp={modalData.setViews}
              triggersProp={modalData.triggers}
              setTriggersProp={modalData.setTriggers}
          />
        )
      case "user":
        return (
          <UserView 
              user={modalData.data}
          />
        )

      case "item":
        return (
          <ItemView 
              data={modalData.data}
          />
        )

      case "error":
        return (
          "An error occurred while loading.  Close this window and try again."
        )

    }
  }

  const modalSize = () => {
    const width = window.innerWidth;
    if (width < 650) {
      return width * 0.8;
    } else {
      return 575;
    }
  }


  return (
   
    <div className="Home HeaderGrid">
      <div className='home-header-container'>
        <Header location="home"/>
      </div>
      <div className='BodyGrid'>
        <ProfileSide/>
        <Main/>
        {/* <TrendsSide/> */}
      </div>

      
        <Modal
          opened={opened}
          onClose={closeModal}
          overlayProps={{
            color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
          }}
          size={modalSize()}
          withCloseButton={false}
        >
          {modalData && modalSwitch()}
              
        </Modal>
    
    </div>
   
  )
}

export default Home