import { FaInstagram, FaXTwitter } from 'react-icons/fa6'
import './LandingConnect.css'

import React from 'react'
import { Element } from 'react-scroll'

const LandingConnect = () => {
  return (
    <div className="LandingConnect">
        <Element id='anchor_connect' name='anchor_connect' ></Element>
        <h2 className='landing-aboutHeader'>Connect</h2>
        <div className='landing-connect-container'>
            <span>theprojectnorthstar@gmail.com</span>
            <span className='landing-connect-socialMedia'>
                
                <a href="https://www.instagram.com/the_north_star_project/" target="_blank" rel="noopener noreferrer"><span className='landing-connect-icon'><FaInstagram size={30}/></span></a>
                <a href="https://twitter.com/proj_north_star" target="_blank" rel="noopener noreferrer"><span className='landing-connect-icon'><FaXTwitter size={30}/></span></a>
            </span>
        </div>
    </div>
  )
}

export default LandingConnect