import * as UserApi from "../api/UserRequest.js"
import { getDataFeed } from "./DataFeedAction.js";

export const updateUser = (id, formData) => async(dispatch)=> {
    dispatch({type: "UPDATE_USER_START"})
    try {
        const {data} = await UserApi.updateUser(id, formData);
        dispatch({type: "UPDATE_USER_SUCCESS", data: data}) 
    } catch (error) {
        dispatch({type: "UPDATE_USER_FAIL"})
    }
}

export const updateTransferrence = (id, data) => async(dispatch) => {
    dispatch({type: "UPDATE_TRANSFERRENCE_START"})
    try {
        await UserApi.updateTransferrence(id, data);
        dispatch({type: "UPDATE_TRANSFERRENCE_SUCCESS", data: data})
    } catch (error) {
        dispatch({type: "UPDATE_TRANSFERRENCE_FAIL"})
    }
}

export const followUser = (details, data) => async(dispatch) => {
    dispatch({type: "FOLLOW_USER_START", details: details})
    dispatch({type: "ACTION_FEED_ADJUST", data: {user: details.user, action: "follow"}})
    try {
        await UserApi.followUser(details.user._id, data);
        dispatch({type: "FOLLOW_USER_SUCCESS"});
    } catch (error) {
        dispatch({type: "FOLLOW_USER_FAIL"});
    }
}

export const subscribeUser = (details, data) => async(dispatch) => {
    console.log("subscribe user top")
    dispatch({type: "SUBSCRIBE_USER_START", details: details})
    dispatch({type: "ACTION_FEED_ADJUST", data: {user: details.user, action: "subscribe"}})
    console.log("subscribe user after initial dispatches");
    try {
        console.log("subscribeUser inside try");
        console.log(details.user._id);
        console.log(data);
        // THE 0.1-116 PROBLEM IS RIGHT HERE
        await UserApi.subscribeUser(details.user._id, {"sessionUserId": data._id});
        dispatch({type: "SUBSCRIBE_USER_SUCCESS"});
        console.log("ACTION SUCCESS");
    } catch (error) {
        console.log("subscribeUser inside catch");
        dispatch({type: "SUBSCRIBE_USER_FAIL"});
    }
}