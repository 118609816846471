import { AiOutlineBarChart, AiOutlineCheck } from 'react-icons/ai';
import { generateItemTypeIcon } from '../../../../tools/itemTypeConversion';
import './RelationalItem.css';

import React, { useState } from 'react'
import { HiArrowsRightLeft } from 'react-icons/hi2';
import { TbCompass } from 'react-icons/tb';
import { BsEye } from 'react-icons/bs';
import { MdArrowForwardIos, MdOutlineSell } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModalItem } from '../../../../actions/ModalAction';
import { VscTypeHierarchy } from 'react-icons/vsc';
import DatumList from '../Datum/DatumList';
import { CgSpaceBetween } from 'react-icons/cg';
import { FaHandsHoldingCircle, FaRegHandshake } from 'react-icons/fa6'
import { IoHammerOutline } from 'react-icons/io5'

const RelationalItem = ( { item, parentItemObj } ) => {

    const {user} = useSelector((state)=>state.authReducer.authData);
    const dispatch = useDispatch();
    const [content, setContent] = useState(null);

    const loadItem = () => {
        var logObject = {
            location: "relational item",
            ...parentItemObj
        };
        dispatch(fetchModalItem(item.itemId, user._id, logObject));
    }
    
    const displayData = (id) => {
        setContent(item.relatedData.map((datum, id)=> {
            return <DatumList data={datum} key={id} />
        }))
        // const contentEl = document.getElementById("ri_content_" + id);
        // if (contentEl.style.display !== "block") {
        //     contentEl.style.display = "block";
        // } else {
        //     contentEl.style.display = "none";
        // }
        document.getElementById("ri_content_" + id).classList.toggle("General-hidden");
        document.getElementById("ri_data_button_" + id).classList.toggle("ri-button-selected")
        document.getElementById("ri_data_arrow_" + id).classList.toggle("ri-rotated")
        // document.getElementById("ri_items_button_" + id).classList.remove("ri-button-selected")
        // document.getElementById("ri_items_arrow_" + id).classList.remove("ri-rotated")
    }



  return (
    <div className="RelationalItem FlexColumn">
        <div className="ri-item-bar FlexRow">
            <div className="ri-pre-icons ri-spacing FlexColumn">
                <span id={"ri_data_button_" + item.itemId} className='ri-icon-group ri-spacing FlexRow' onClick={()=>displayData(item.itemId)}>
                    <AiOutlineBarChart/><MdArrowForwardIos id={"ri_data_arrow_" + item.itemId} className='ri-arrow' />
                </span>
                {/* <span id={"ri_items_button_" + item.itemId} className='ri-icon-group ri-spacing FlexRow'>
                    <VscTypeHierarchy/><MdArrowForwardIos id={"ri_items_arrow_" + item.itemId} className='ri-arrow' />
                </span> */}
            </div>
            <div 
                id={"relational_item_" + item.itemId} 
                className={ item.transferrence === 1 && item.triggers > 0
                                ? "ri-item-container FlexRow ri-triggered" 
                                : "ri-item-container FlexRow ri-normal"
                } 
                // onClick={   loaded 
                //                 ? toggleDeleteButton 
                //                 : selectItem
                // }
            >
                <div className="arm-link-item-imageContainer">
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + item.image} />
                </div>
                <div className='arm-link-item-infoContainer FlexColumn'>
                    <div className="arm-link-item-nameBar FlexRow">
                        <div className="arm-link-item-itemName">{item.itemName}</div>
                        <div className='arm-link-item-itemIcon'>{generateItemTypeIcon(item.itemType)}</div>
                    </div>
                    <div className='ri-stats-bar'>
                        <span className='ri-stat FlexRow'>
                            <span>
                                <HiArrowsRightLeft /><AiOutlineBarChart />
                            </span>
                            {item.relatedData.length}
                        </span>
                        {/* <span className='ri-stat FlexRow'>
                            <span>
                                <HiArrowsRightLeft /><VscTypeHierarchy />
                            </span>
                            ?
                        </span> */}
                        <span className="ri-stat FlexRow">
                            {item.triggers > 0 && <><TbCompass />{item.triggers}</>}
                        </span>
                    </div>
                    {/* Commented out because it's too wide on some small mobile screens.  Needs to expand on click. */}
                    {/* <div className="ri-stats-bar FlexRow">
                        {item.transferrence && <div className='ri-transferrence FlexRow'><CgSpaceBetween /><AiOutlineCheck /></div>}
                        <span className="ri-stat FlexRow">
                            <FaHandsHoldingCircle />
                            {item.relationCounts.owns}
                        </span>
                        <span className="ri-stat FlexRow">
                            <FaRegHandshake />
                            {item.relationCounts.supports}
                        </span>
                        <span className="ri-stat FlexRow">
                            <IoHammerOutline />
                            {item.relationCounts.makes}
                        </span>
                        <span className="ri-stat FlexRow">
                            <MdOutlineSell />
                            {item.relationCounts.sells}
                        </span>
                    </div> */}
                </div>
            </div>
            <div className="ri-post-icons FlexRow">
                <BsEye onClick={loadItem}/>
            </div>
        </div>
        <div id={"ri_content_" + item.itemId} className="ri-content General-hidden">
            {content}
        </div>
    </div>
  )
}

export default RelationalItem