const modalReducer = (
    state = { modalData: null, rModalOpened: false, loading: false, error: false }, 
    action
)   => {
        switch(action.type) {
            
            // I think this should have start, success, and fail just like the user and item
            case "MODAL_LOAD":
                return{...state, modalData: action.modalPackage, rModalOpened: true};
            
            case "MODAL_FETCH_USER_START":
                // console.log("MODAL_FETCH_USER_START")
                return{...state, loading: true, error: false};
            case "MODAL_FETCH_USER_SUCCESS":
                // console.log("MODAL_FETCH_USER_SUCCESS")
                // console.log(action.data);
                return{...state, modalData: {modalType: "user", data: action.data}, rModalOpened: true, loading: false};
            case "MODAL_FETCH_USER_FAIL":
                return{...state, modalData: {modalType: "error"}, rModalOpened: false, loading: false, error: true};
            

            case "MODAL_FETCH_ITEM_START":
                // console.log("MODAL_FETCH_ITEM_START")
                return{...state, loading: true, error: false};
            case "MODAL_FETCH_ITEM_SUCCESS":
                // console.log("MODAL_FETCH_ITEM_SUCCESS")
                // console.log(action.data);
                return{...state, modalData: {modalType: "item", data: action.data}, rModalOpened: true, loading: false};
            case "MODAL_FETCH_ITEM_FAIL":
                return{...state, modalData: {modalType: "error"}, rModalOpened: false, loading: false, error: true};
            
            
            case "MODAL_CLOSE":
                return{...state, rModalOpened: false};

            case "LOG_OUT":
            return {modalData: null, rModalOpened: false, loading: false, error: false}

            default: 
                return state
        }
        
    };

export default modalReducer;