import React from 'react'
import './ProfileCard.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import UserStats from '../../User/UserStats'
import { Element } from 'react-scroll'
import { LuRefreshCcw } from 'react-icons/lu'
import { refreshAuthData } from '../../../actions/AuthAction'


const ProfileCard = ( {location} ) => {

    const {user} = useSelector((state) => state.authReducer.authData);
    const {loading: authDataLoading} = useSelector((state) => state.authReducer);
    const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
    const dispatch = useDispatch();
    // console.log("Location value: " + location);

    const refreshAuth = () => {
        console.log("Refreshing authData")
        dispatch(refreshAuthData({"username": user.username}));
    }

  return (
    <>
        <div className="AppGeneralCard">
            
            
            <div className="ProfileImages">
                <img src={serverPublic + user.userCover} alt="" />
                <img src={serverPublic + user.userImage} alt="" />
            </div>
            <div className="ProfileName">
                <span className='pc-un-line'>
                    <LuRefreshCcw onClick={refreshAuth} className={authDataLoading ? 'refresh-spin' : ''}/>    
                    
                    {user.displayname}
                </span>
                <span>@{user.username}</span>
            </div>
            <div className='pc-stats FlexColumn'>
                <UserStats user={user} />
            </div>    
            {location === 'profilePage' 
            ? ""
            : 
            <>
                <hr/>
                <div className="edit-link">
                    <Link 
                        style={{textDecoration: "none", color: "inherit"}} 
                        to={`/profile/${user._id}`}
                    >
                        View Full Profile
                    </Link>
                </div>
            </>
            }
            
            <Element id='anchor_users' name='anchor_users' className="anchor-position-offset"></Element>
        </div>
    </>
  )
}

export default ProfileCard