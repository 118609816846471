import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { Provider } from 'react-redux'
import store from './store/ReduxStore.js'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'; 

if (process.env.NODE_ENV === 'production') disableReactDevTools()

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path = "*" element = {<App/>}/>
          </Routes>
        </BrowserRouter>
    </React.StrictMode>
  </Provider>,
)
