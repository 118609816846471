const itemReducer = (
    state = { itemData: null, loading: false, error: false }, 
    action
)   => {
        switch(action.type) {
            case "ITEM_SUBMIT_START":
                return{...state, loading: true, error: false};
            case "ITEM_SUBMIT_SUCCESS":
                return{...state, itemData: action.data, loading: false, error: false};
            case "ITEM_SUBMIT_FAIL":
                return{...state, loading: false, error: true};
            default: 
                return state
        }
        
    };

export default itemReducer;