import { BsPersonVcard } from "react-icons/bs";
import { FiBox } from "react-icons/fi";
import { MdOutlineBusiness } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { TbCategory2 } from "react-icons/tb";
import { VscTypeHierarchy } from "react-icons/vsc";

export const generateItemTypeIcon = (type) => {
    switch (type) {
    case 0:
        // Product
        // Let's get some colors on these!
        return <FiBox size="1rem" className='item-icon-product'/>;
    case 1:
        // Organization
        return <MdOutlineBusiness size="1rem" className='item-icon-organization'/>
    case 2:
        // Individual
        return <BsPersonVcard size="1rem" className='item-icon-individual'/>
    case 3:
        // Location
        return <SlLocationPin size="1rem" className='item-icon-location' />
    case 4:
        // Category
        return <TbCategory2 size="1rem" className='item-icon-category' />
    default:
        return <VscTypeHierarchy size="1rem" className='item-icon'/>
    }
}
