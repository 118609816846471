import { FaHandsHoldingCircle, FaRegHandshake } from "react-icons/fa6";
import { HiArrowsRightLeft } from "react-icons/hi2";
import { IoHammerOutline } from "react-icons/io5";
import { MdOutlineFrontHand, MdOutlineSell, MdWorkOutline } from "react-icons/md";
import { TbCategory2, TbReplace } from "react-icons/tb";

export const generateRelationIcon = (relation) => {
    switch (relation) {
    case "owns":
        // Owns
        return <FaHandsHoldingCircle className=''/>;
    case "makes":
        // Makes
        return <IoHammerOutline className=''/>;
    case "supports":
        // Supports
        return <FaRegHandshake className=''/>;
    case "opposes":
        // Opposes
        return <MdOutlineFrontHand className=''/>;
    case "sells":
        // Sells
        return <MdOutlineSell className=''/>;
    case "employs":
        // Employs
        return <MdWorkOutline className=''/>;
    case "replaces":
        // Replaces
        return <TbReplace className=''/>;
    case "belongs to (category)":
        // Belongs to (category)
        return <TbCategory2 className=''/>;

    // Missing employs, type (parent/child)


    default:
        return <HiArrowsRightLeft className=''/>
    }
}
