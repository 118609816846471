import { Element } from 'react-scroll';
import Header from '../../components/Header/Header';
import LandingAbout from '../../components/Landing/LandingAbout/LandingAbout';
import LandingLogo from '../../components/Landing/LandingLogo/LandingLogo';
import LandingStats from '../../components/Landing/LandingStats/LandingStats';
import './Landing.css';

import React from 'react'
import LandingInvolved from '../../components/Landing/LandingInvolved/LandingInvolved';
import LandingResources from '../../components/Landing/LandingResources/LandingResources';
import { sendLog } from '../../api/LogsRequest';
import { logVisitorConnectionDetails } from '../../tools/visitorConnection';
import { useSelector } from 'react-redux';
import LandingConnect from '../../components/Landing/LandingConnect/LandingConnect';


const Landing = () => {

  const user = useSelector((state)=>state.authReducer.authData?.user);

  if (user) {
    logVisitorConnectionDetails({activity: "user_visit", userId: user._id, username: user.username});
  } else {
    logVisitorConnectionDetails(null);

  }

  return (
    <div className="FlexColumn HeaderGrid">
        <div className='landing-header-container'>
            <Element id='anchor_landing_top' name='anchor_landing_top' ></Element>

            <Header location="landing"/>
        </div>
        <div className='FlexColumn'>
            <LandingLogo />
            <LandingAbout />
            <LandingStats />
            <LandingInvolved />
            <LandingResources />
            <LandingConnect />
        </div>
    </div>
  )
}

export default Landing


// Adjust Header to change based on logged in status