import React, {useState, useRef} from 'react'
import './CreateItem.css'
import { useDispatch, useSelector } from 'react-redux'
import { submitItem } from '../../../actions/ItemAction'
import { BsCardImage } from 'react-icons/bs'
import { LiaTimesSolid } from 'react-icons/lia'
import { VscTypeHierarchy } from 'react-icons/vsc'
import { IoLocationOutline } from 'react-icons/io'
import { uploadMedia } from '../../../actions/uploadAction'

export const CreateItem = ( {setMainContent} ) => {

    const dispatch = useDispatch();
    const loading = useSelector((state)=>state.itemReducer.loading);

    const {user} = useSelector((state)=>state.authReducer.authData);
    
    const blankDataObj = {
        userId: user._id,
        itemName: "",
        itemType: 0,
        links: [],
        adoptions: [user._id],
        image: "",
        qr: ""
    }

    const [data, setData] = useState(blankDataObj);
    const [image, setImage] = useState(null);
    const imageRef = useRef();
    
    const onImageChange = (event)=> {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            console.log("Inside onImageChange true");
            setImage(img);
        }
    }; 

    const [validationError, setValidationError] = useState([false, ""]);

    const displayValidationError = (code) => {
        switch (code) {
            case 0:
                console.log("Inside 0");
                setValidationError([true, "You must have a Name for your new item."]);
                break;
            case 1:
                console.log("Inside 1");
                
                setValidationError([true, "You must upload an Image for your new item."]);
                break;
            case 2:
                console.log("Inside 2");
                
                setValidationError([true, "The image filesize must be less than 1mb"]);
                break;
        }
        console.log("Error text: " + validationError[1]);
    }

    const handleSubmit = (e)=> {
        e.preventDefault();
        if (data.itemName) {
            
            if (image) {
                const fileSize = image.size;
                if (fileSize <= 1000000) {
                    const formData = new FormData();
                    const filename = Date.now() + "_" + user._id;
                    formData.append("name", filename)
                    formData.append("file", image)
                    formData.append("upload_preset", 'asx6uxrg');
                    formData.append("public_id", filename);
                    data.image = filename + ".png";

                    // For Demo - delete later
                    if (user.isAdmin) {
                        data.isAdmin = true;
                    }


                    try {
                        dispatch(submitItem(data));
                        dispatch(uploadMedia(formData))
                        setMainContent({current: "dataFeed", previous: "c_item"});
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    displayValidationError(2);
                }
            } else {
                console.log("Val Error: 1");
                displayValidationError(1);
            }
        } else {
            console.log("Val Error: 0");
            displayValidationError(0);
        }
    }

    const handleChange = (e)=> {
        setData({...data, [e.target.name]: e.target.value});
    }

  return (
    <div className="CreateItem">
        <div className='ci-main-card'>
            <form action="" className="input-form" onSubmit={handleSubmit}>
                <span className='ci-input-span'>
                  <VscTypeHierarchy className='itemIcon'/>
                  <h3>Create Item</h3>
                </span>
                
                <input 
                    type="text"
                    placeholder='Item Name'
                    className='itemInput'
                    name='itemName'
                    onChange = {handleChange}
                />
                <label 
                    name="itemType"
                    className='itemTypeDropdownLabel'
                >
                    <span>
                        Item Type:
                    </span>
                    <select 
                        name="itemType" 
                        className='itemTypeDropdown'
                        onChange = {handleChange}
                    >
                        <option value="0">Product</option>
                        <option value="1">Organization</option>
                        <option value="2">Individual</option>
                        <option value="3">Location</option>
                        <option value="4">Category</option>
                    </select>
                </label>
                <label 
                    name="itemType"
                    className='itemTypeDropdownLabel'
                >
                    <span>
                        Image:
                    </span>
                    <div 
                        className='ci-imageIconContainer'
                        onClick={()=>imageRef.current.click()}
                    >
                        <BsCardImage size={15}/>
                    </div>
                </label>

                {image && (
                    <div className="previewImage">
                        <img src={URL.createObjectURL(image)} alt="" />
                        <LiaTimesSolid onClick={()=>setImage(null)}/>
                    </div>
                )} 

                <span 
                    className="validationError"
                    id="validationError"
                    style={{display: validationError[0] ? "block" : "none"}}
                >
                    {validationError[1]}
                </span>
                
                <button className="button signupButton" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"} 
                </button>   
                <div style={{display: "none"}}>
                    <input type="file" ref={imageRef} onChange={onImageChange}/>
                </div>
                
            </form>
        </div>
    </div>
  )
}
