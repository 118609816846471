import './LandingLogo.css';
import Logo from '../../../img/crop.png'


import React from 'react'

const LandingLogo = () => {
  return (
    <div className="LandingLogo">
        <img src={Logo} alt="" />
    </div>
  )
}

export default LandingLogo