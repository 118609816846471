import * as ValuesApi from "../api/ValuesRequest.js"

export const createValue = (id, data) => async(dispatch) => {
    console.log("Inside Value Action Create Value")
    console.log("Data:")
    console.log(data);
    try {
        ValuesApi.createValue(id, data)
        dispatch({type: "CREATE_VALUE", data: data})
    } catch (error) {
        console.log(error)
    }
}
