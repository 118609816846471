import React, { useState } from 'react'
import './FollowBar.css'
import { BiPen, BiUser } from 'react-icons/bi'
import { BsBookmarkCheck } from 'react-icons/bs'
import { RiUserFollowLine } from 'react-icons/ri'
import { Element } from 'react-scroll'

const FollowBar = ( { activeType, typeSelector }) => {

  const [active, setActive] = useState(activeType)

  const makeActive = (selected) => {
    const e1 = document.getElementById(selected);
    const e2 = document.getElementById(active);
    if (e1 != e2) {
      e1.classList.add("activeCard");
      e2.classList.remove("activeCard");
      setActive(selected);
      typeSelector(selected);
    }
  }

  const selectAuthors = () => {
    makeActive("f_authors")
  }

  const selectGeneral = () => {
    makeActive("f_general")
  }

  return (
    <div className="FollowBar">
      
        
        <div id="f_authors" className="fb-div activeCard" onClick={selectAuthors}>
            <BsBookmarkCheck/>
        </div>
        <div id="f_general" className="fb-div" onClick={selectGeneral}>
          <RiUserFollowLine/>
        </div>
    </div>
  )
}

export default FollowBar