import { scrollToAnchor } from '../../src/tools/scrollToAnchor'

export const enText = {
    landingAbout: {
        whatIs_title: "What is North Star?",
        whatIs_body: <span>North Star is a free tool that <b>helps you to make decisions that better align with your personal values.</b> Create an account (no payment is involved) and begin following Groups whose values align with yours.  Then, search for a product, business, or person (called <b>Items</b>) to see if it aligns with your values - and to learn about alternatives if it doesn't.</span>,
        how_title: "How does it work?",
        how_body: <span>Content on North Star is entirely user-generated by <b style={{color: "var(--userTypeAuthor)"}}>Authors</b>.  Other users called <b style={{color: "var(--userTypeGroup)"}}>Groups</b> advocate for a specific cause and vet this information to ensure that it's accurate and relevant to their cause.  You choose which Groups you want to follow based both on how closely their values align with yours and how much you trust them as an authority on the topic.  Only the information that your followed Groups have vetted will make it to you, the end user.  This makes sure that you're given only trustworthy information about the topics that matter to you!</span>,
        
        vision_title: "Our Vision",
        vision_body1: <span><b>When North Star reaches full functionality,</b> we envision a tool with unprecedented ability to vet everyday things in your life against your full set of values.  You'll be able to scan the bar code on the back of the product in your hand, or search for the name of a person of business, and be instantly provided with a comprehensive summary of critical information about it that may sway your decision to buy/support it.  The information could vary widely and span various Topics and Values (more on these below).  A simple tube of makeup or a cup of coffee can have a winding, complex story once you dig under the surface:</span>,
        vision_body_li1: "were the raw materials mined/grown ethically?",
        vision_body_li2: "are there any toxic ingredients?",
        vision_body_li3: "were the products tested on animals?",
        vision_body_li4: "was it produced locally?",
        vision_body_li5: "does the manufacturer engage in activism that I don't agree with?",
        vision_body2: <><span>Keeping up with all of these details about every product, business, or person is an overwhelming and unrealistic endeavor.  But there are already countless individuals and organizations whose mission is to advocate for a cause that they believe in.  The information is generally available, but not in a convenient and unified format.</span><br/><br/><span><b>North Star fixes this!</b></span></>,
        
        getThere_title: "How do we get there?",
        getThere_body1: <span>The path to full functionality will unfold in Phases, by incrementally adding <b>Topics</b> as well as helpful new features to the user interface.  A Topic is simply a cause that users care about and which usually aligns with a particular <b>Value</b>.  So, a user might want to make sure that none of her makeup is tested on animals (Topic) so that she makes sure she isn't supporting any products or businesses that are cruel to animals (Value).  The Values are the underlying principles that we want to live by, and the Topics are where they intersect with the real world.
        <br/><br/>
        <i>Expand the sections below to learn more.</i>
        </span>,
        getThere_kickoff_title: <span>Kickoff Topic Challenge</span>,
        getThere_kickoff_body: <span>The reason for including the right Topics at the right Phase is to make sure that a user can always be confident that North Star will return useful and relevant information about an Item as long as it relates to one of the live Topics.  This avoids a scenario in which the database is incomplete and missing critical information that a user would expect to find.  If North Star tried to tackle a Topic as broad as "Environmentalism" from the beginning, a user would almost certainly find himself searching for Items that don't yet exist or finding Items that have clear gaps in information.  However, if a user knows before searching for an Item whether North Star is ready to provide info on it, it can be used as a reliable tool even from its first days.
        <br/><br/>
        Another aspect of this challenge is beginning with a Topic that will spur large-scale Items and Data creation.  A Topic that is contained to only a few companies or individuals, such as "Boycot Monsanto" may be adequately specific and helpful for shedding light on those very few Items, but it doesn't cast a relatively wide net when it comes to the associated Items and Data.
        <br/><br/>
        By starting with a manageable and well-defined Topic, we can begin building a solid foundation of Data so that each subsequently added Topic colors more of the picture.  For this reason, the "Support Local" movement is the perfect Kickoff Topic.  It's focused and specific to a local area, so the Items a user might search for is limited, yet it can reasonably include many types of products and businesses.  It's forgiving, as it's not obvious what information one should find about a given Item, and so 'gaps' aren't perceived as conspicuous.  And perhaps most importantly, it's a Topic that truly matters to us and showcases North Star's potential for meaningful change right out of the gates!</span>,
        getThere_phases_title: <span>Phases</span>,
        getThere_phase1: <span>Phase 1 is all about liftoff.  North Star will be put to use on a single Topic, or a very limited few Topics, and we'll make sure it's working like it should.  Users should be able to search for an Item that they would reasonably expect to be included given the live Topics and find it in our database, with helpful info about it!</span>,
        getThere_phaseFeatures_title: <span>Planned features for this Phase:</span>,
        getThere_phase1_feature1: <span><b>Relational Items:</b> learn about the relationship an Item has with other Items.  Does Company A own Company B?  Did Company X financially support Person Y?  This feature will help to shed light on these relationships.</span>,
        getThere_phase1_feature2: <span><b>Suggested Items:</b> when an Item conflicts with one or more of your values, your Groups can suggest alternatives that are a better fit</span>,
        getThere_phase1_feature3: <span><b>Relational Search:</b> use this tool to search not just for an Item, but two Items that have a particular Relation.  This feature will allow you to find alternatives if you want to buy/support one Item, but not the other, like if you want to buy a product from a store that aligns more with your values.</span>,
        getThere_phase1_target: <span>Target: December 2023</span>,
        getThere_phase2: <span>Phase 2 will be a growth phase in which a few more Topics and Values will be added, but as with Phase 1, it will be done in a measured and methodical way.  While in Phase 1 the full potential of North Star may not have been obvious, Phase 2 will begin to showcase just how powerful of a tool it can be.  Each new Topic that is added strengthens all of the previous Topics as new content is created that may begin to show Topic overlap on Items, reinforcing North Star as a comprehensive repository of relevant information.</span>,
        getThere_phase2_feature1: <span><b>Mobile App:</b> a fully-functional mobile app will be made available for Android and iOS to compliment the website/web app.</span>,
        getThere_phase2_feature2: <span><b>Deep Scans:</b> in Phase 1, searching for an Item will also bring you information about other Items that it's related to.  This is helpful for learning about close relationships that the Item has with other Items which may conflict with your values.  With Deep Scans, your searches will be able to traverse several more layers of Related Items.  This is helpful, for example, for following the trail of responsibility when several "shell companies" are used to obfuscate the link between two entities.</span>,
        getThere_phase2_feature3: <span><b>Multiple Language Support:</b> language support will be added for several major languages, yet to be determined.</span>,
        getThere_phase2_target: <span>Target: May 2024</span>,
        getThere_phase3: <span>Phase 3 will be where the boosters fall away and the shuttle begins to fly.  We'll see the inclusion of some big Topics and many niche Topics which will make North Star robust.  At this point, you should begin to reliably search for just about any major product, business, or person and be confident that the information you're returned contains the things you need to know to assess the Item against your unique set of values.</span>,
        getThere_phase3_target: <span>Target: 2025</span>
    },
    landingStats: {
        phase_body: <span>The shuttle is rumbling on the platform and countdown has begun!  The launch will occur in <a href="#" onClick={()=>scrollToAnchor("anchor_phases")}><u>Phases</u></a> that increase in the type and complexity of the Topics and Values that we include.  We're getting the first Authors and Groups involved now, and we're laying the groundwork for the first Topics. </span>,
        topicsDesc: <span>North Star is designed to guide the user toward more informed decisions no matter the Topic, whether it's human rights, animal rights, environmentalism, politics, personal values, or dietary preference.  But choosing which Topic(s) to begin with is critical.  See <a href="#" onClick={()=>scrollToAnchor("anchor_kickoff")}><u>The Kickoff Challenge</u></a> for further explanation.  As Topics are included in North Star, they'll be added here so that you can stay on top of all of the ways that you can use North Star to make the most informed decisions.</span>,
        topics_supportLocal_Tri: <span>Support Local: Northeast TN</span>,
        topics_supportLocal_Tri_details: <span>Do you believe in supporting local businesses?  Would you rather see your dollars stay right here at home, supporting your community and strengthening its ties?  Use this Topic to make sure that your purchase decisions do exactly that!  
        <br/><br/>This very first Topic is one that's near and dear to us - literally!  North Star was started in Johnson City, nestled in the foothills of the Appalachian mountains in Northeast Tennessee.  The first Topic deals with supporting locally owned businesses and locally made products.  
        <br/><br/>Just search for a product or business on North Star and learn if there's a locally owned or made alternative to the one you planned to buy (or buy from).  If there is, it will be suggested to you so you can choose that one instead - the one that better aligns with your value of 'supporting local'.
        <br/><br/>We are currently establishing connections with businesses and local community development groups in the following areas:  Johnson City, Jonesborough, Greeneville, Elizabethton.  Don't wait for us though!  Anyone can create an account and get set up to start supporting their local community anywhere in the world!  Check out our <a href="#" onClick={()=>scrollToAnchor("anchor_resources")}><u>Resources</u></a> section for helpful documents on getting started.
        <br/><br/>If you're an Author creating content, use the suggested #themes for your respective community to make sure your entries get seen!</span>,
        topics_supportLocal_JC: <span>Johnson City</span>,
        topics_supportLocal_JC_themes: <span>#ShopLocalJohnsonCity, #MadeInJohnsonCity</span>,
        topics_supportLocal_Jones: <span>Jonesborough</span>,
        topics_supportLocal_Jones_themes: <span>#ShopLocalJonesborough, #MadeInJonesborough</span>,
        topics_supportLocal_Greene: <span>Greeneville</span>,
        topics_supportLocal_Greene_themes: <span>#ShopLocalGreeneville, #MadeInGreeneville</span>,
        topics_supportLocal_Eliz: <span>Elizabethton</span>,
        topics_supportLocal_Eliz_themes: <span>#ShopLocalElizabethton, #MadeInElizabethton</span>,
        topics_supportLocal_Kings: <span>Kingsport</span>,
        topics_supportLocal_Bristol: <span>Bristol</span>,
        topics_politicsTN: <span>Politics: TN</span>
    },
    landingResources: {
        header: "Resources",
        authorWalkTitle: "Author Setup Walkthrough",
        authorWalkDescription: "If you're planning to create content on North Star, then this resource is what you're looking for.",
        groupWalkTitle: "Group Setup Walkthrough",
        groupWalkDescription: "If you're an organization with a value-driven mission statement, and want to use North Star to bring information to your followers, choose this one."
    }
}