import React, { useEffect, useState } from 'react'
import './CreateDatum.css';
import { useDispatch, useSelector } from 'react-redux'
import { clearDatumStatus, submitDatum } from '../../../actions/DatumAction'
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md';
import { VscTypeHierarchy } from 'react-icons/vsc';
import { BiHash } from 'react-icons/bi';
import AddReferenceModal from './AddReferenceModal/AddReferenceModal';
import Reference from './AddReferenceModal/Reference/Reference';
import AddSourceModal from './AddSourceModal/AddSourceModal';
import AddThemeModal from './AddThemeModal/AddThemeModal';
import { HiArrowsRightLeft } from 'react-icons/hi2';
import AddRelationModal from './AddRelationModal/AddRelationModal';
import Relation from './AddRelationModal/Relation/Relation';



const CreateDatum = ( {setMainContent} ) => {

  const dispatch = useDispatch();
  const {loading, error, status} = useSelector((state)=>state.datumReducer);
  const {user} = useSelector((state)=>state.authReducer.authData);
  
  const blankDataObj = {
    userId: user._id,
    datumText: "", 
    references: [], 
    relations: [],
    sources: [], 
    themes: []
  }

  const [data, setData] = useState(blankDataObj);
  const [openARM, setOpenARM] = useState(false);
  const [dataARM, setDataARM] = useState([]);
  const [openARelM, setOpenARelM] = useState(false);
  const [dataARelM, setDataARelM] = useState([]);
  const [openASM, setOpenASM] = useState(false);
  const [dataASM, setDataASM] = useState([]);
  const [openATM, setOpenATM] = useState(false);
  const [dataATM, setDataATM] = useState([]);


  const handleSubmit = (e)=> {
    e.preventDefault();

    // Validation
    const valEl = document.getElementById("cd_validation");
    if ((data.datumText === "" || data.datumText.length < 10) && dataARelM.length < 1) {
      valEl.innerText = "Please enter text for your datum that is at least 10 characters long.  If you would like to create a Datum without text, you must have at least 1 Relation indicated.";
      document.getElementById("cd_datumText").classList.add("cd-input-red");
      valEl.classList.remove("General-hidden");
    } else if (dataARM.length === 0) {
      valEl.innerText = "You must reference at least one item.";
      document.getElementById("cd_references").classList.add("cd-input-red");
      valEl.classList.remove("General-hidden");
    } else {
      submit(); 
    }
  }

  const submit = () => {
    // load references
    for (let i = 0; i < dataARM.length; i++) {
      data.references.push(dataARM[i]._id);
    }

    // load relations
    for (let i = 0; i < dataARelM.length; i++) {
      data.relations.push(dataARelM[i]);
    }

    // load sources
    for (let i = 0; i < dataASM.length; i++) {
      data.sources.push(dataASM[i]);
    }

    // load themes
    for (let i = 0; i < dataATM.length; i++) {
      data.themes.push(dataATM[i].theme);
    }

    dispatch(submitDatum(data));
  }

  useEffect(()=>{
    if (status) {
      if (error) {
        const valEl = document.getElementById("cd_validation");
        valEl.innerText = error;
        valEl.classList.remove("General-hidden");
      } else {
        dispatch(clearDatumStatus());
        setMainContent({current: "dataFeed", previous: "c_datum"})
      }
    }
  }, status)

  const removeBorder = (id) => {
    const el = document.getElementById(id);
    if (el.classList.contains("cd-input-red")) {
      el.classList.remove("cd-input-red");
    }
  }

  const handleChange = (e)=> {
    setData({...data, [e.target.name]: e.target.value});
  }

  // Set ARM behavior
  useEffect(() => {
    const alertEl = document.getElementById("cd_arm_alert");
    const armOpenEl = document.getElementById("cd_arm_open");
    if (dataARM.length == 5) {
      alertEl.style.display = "block";
      alertEl.innerText = "Maximum number of references reached.";
      armOpenEl.onclick = null;
      setOpenARM(false);
    } else {
      alertEl.style.display = "none";
      armOpenEl.onclick = () => {
        setOpenARM(true);
        removeBorder("cd_references")
      }
    }
  }, [dataARM])

  // Set ARelM behavior
  useEffect(() => {
    const alertEl = document.getElementById("cd_relation_alert");
    const arelmOpenEl = document.getElementById("cd_relation_open");
    
    if (dataARelM.length > 0 && dataARelM[0].relation === "replaces") {
      // Disallows additional relations if the first set relation is "replaces"
      alertEl.style.display = "block";
      console.log(alertEl);
      console.log(arelmOpenEl);
      alertEl.innerText = "If a suggestion is being made, it can be the only relation.";
      arelmOpenEl.onclick = null;
      setOpenARelM(false);
    } else {
      if (dataARelM.length == 5) {
        alertEl.style.display = "block";
        alertEl.innerText = "Maximum number of relations reached.";
        arelmOpenEl.onclick = null;
        setOpenARelM(false);
      } else {
        alertEl.style.display = "none";
        arelmOpenEl.onclick = () => setOpenARelM(true);
      }
    }

  }, [dataARelM])

  // Set ASM behavior
  useEffect(() => {
    const alertEl = document.getElementById("cd_asm_alert");
    const asmOpenEl = document.getElementById("cd_asm_open");
    alertEl.style.display = "none";
    asmOpenEl.onclick = () => setOpenASM(true);
    
  }, [dataASM])

  // Set ATM behavior
  useEffect(() => {
    console.log("Inside useEffect with dataATM array at: " + dataATM.length);
    const alertEl = document.getElementById("cd_atm_alert");
    const atmOpenEl = document.getElementById("cd_atm_open");
    if (dataATM.length == 5) {
      alertEl.style.display = "block";
      alertEl.innerText = "Maximum number of themes reached.";
      atmOpenEl.onclick = null;
      setOpenATM(false);
    } else {
      alertEl.style.display = "none";
      atmOpenEl.onclick = () => setOpenATM(true);
    }
  }, [dataATM])


  const prepareSourceMarkup = (source, index) => {
    return (
      <div className='cd-asm-container FlexRow' onClick={()=>toggleDeleteButton("asm_delete_" + index)}>
        <BsTrash id={"asm_delete_" + index} className="cd-delete" onClick={()=>removeSource(index)} />
        <div className="cd-source-info">
          <div className='cd-asm-text'>{source.text}</div>
          <div className='cd-asm-link'>{source.link}</div>
          <div className='cd-asm-description'>{source.description}</div>
        </div>
      </div>
    )
  }

  const prepareThemeMarkup = (theme, index) => {
    return (
      <div className='cd-atm-container FlexRow' onClick={()=>toggleDeleteButton("atm_delete_" + index)}>
        <BsTrash id={"atm_delete_" + index} className="cd-atm-delete" onClick={()=>removeTheme(index)} />
        <div className='cd-atm-trend'>{theme.theme}</div>
      </div>
    )
  }

  const toggleDeleteButton = (id) => {
    console.log("ToggleDelete triggered with id: " + id);
    const deleteButtonEl = document.getElementById(id);
    console.log(deleteButtonEl.style.display);
    if (deleteButtonEl.style.display == "none" || deleteButtonEl.style.display == "") {
        deleteButtonEl.style.display = "block";
    } else {
        deleteButtonEl.style.display = "none";
    }
    console.log(deleteButtonEl.style.display);
    // This needs to be improved.  The button should take up 80% of the height and width, and should overlay the normal div content
    // The div content behind it should become semi-transparent
}

const removeSource = (index) => {
  setDataASM(() => {
      return [...dataASM.slice(0, index), ...dataASM.slice(index + 1)]
  })
}

const removeTheme = (index) => {
  setDataATM(() => {
      return [...dataATM.slice(0, index), ...dataATM.slice(index + 1)]
  })
}

  return (
    <div className="CreateDatum">
      <div className='cd-main-card'>
        <form action="" className="input-form" onSubmit={handleSubmit}>
                <span className='cd-input-span'>
                  <AiOutlineBarChart className='datumIcon'/>
                  <h3>Create Datum</h3>
                </span>
                <textarea 
                    cols="40"
                    rows="5"
                    placeholder='Write your datum here...'
                    className='cd-input'
                    name='datumText'
                    id="cd_datumText"
                    onChange={handleChange}
                    onFocus={()=>removeBorder("cd_datumText")}
                />
                
                {/* References */}
                <span className='cd-input-span'>
                  <VscTypeHierarchy/>
                  <div id='cd_references' className='cd-input cd-references'>
                    {dataARM.length == 0 ? "No references selected" : 
                    dataARM.map((reference, id) => {
                      // console.log(reference)
                      // console.log("id: " + _id)
                      
                      return (
                        <Reference 
                            reference={reference} 
                            id={id}
                            key={id}
                            dataARM={dataARM}
                            setDataARM={setDataARM}
                            dataARelM={dataARelM}
                            setDataARelM={setDataARelM}
                            location="arm"
                            loaded={true}
                          />
                      )
                    })}
                  </div>
                  <BsPlusLg id="cd_arm_open"/>
                </span>
                <div id="cd_arm_alert" className='cd-arm-alert'></div>
                {openARM && <div className="cd-m-container">
                  <AddReferenceModal 
                    setOpenARM={setOpenARM}
                    dataARM={dataARM}
                    setDataARM={setDataARM}
                  />
                </div>}

                {/* Relations */}
                <span className='cd-input-span'>
                  <HiArrowsRightLeft/>
                  <div id='cd_relations' className='cd-input cd-relations'>
                  {dataARelM.length == 0 ? "No relations indicated" : 
                    dataARelM.map((relation, id) => {
                      console.log(relation)
                      console.log("id: " + id)
                      
                      return (
                        <Relation 
                            relation={relation} 
                            id={id}
                            key={id}
                            dataARM={dataARM}
                            dataARelM={dataARelM}
                            setDataARelM={setDataARelM}
                          />
                      )
                  })}
                  </div>
                  <BsPlusLg id="cd_relation_open"/>
                </span>
                <div id="cd_relation_alert" className='cd-arm-alert'></div>
                {openARelM && <div className="cd-m-container">
                  <AddRelationModal
                    setOpenARelM={setOpenARelM}
                    dataARelM={dataARelM}
                    setDataARelM={setDataARelM}
                    dataARM={dataARM}
                  />
                </div>}

                
                {/* Sources */}
                <span className='cd-input-span'>
                  <MdOutlineAssignmentTurnedIn/>
                  <div id='cd_sources' className='cd-input cd-sources'>
                    {dataASM.length == 0 ? "No sources submitted" : 
                    dataASM.map((source, index) => {
                      console.log(source)
                      return (prepareSourceMarkup(source, index))
                    })}
                  </div>
                  <BsPlusLg id="cd_asm_open"/>
                </span>
                <div id="cd_asm_alert" className='cd-asm-alert'></div>
                {openASM && <div className="cd-m-container">
                  <AddSourceModal 
                    setOpenASM={setOpenASM}
                    dataASM={dataASM}
                    setDataASM={setDataASM}
                  />
                </div>}
                
                {/* Themes */}
                <span className='cd-input-span'>
                  <BiHash/>
                  <div id='cd_themes' className='cd-input cd-themes'>
                    {dataATM.length == 0 ? "No themes selected" : 
                    dataATM.map((theme, index) => {
                      console.log(theme)
                      return (prepareThemeMarkup(theme, index))
                    })}
                  </div>
                  <BsPlusLg id="cd_atm_open"/>
                </span>
                <div id="cd_atm_alert" className='cd-atm-alert'></div>
                {openATM && <div className="cd-m-container">
                  <AddThemeModal
                    setOpenATM={setOpenATM}
                    dataATM={dataATM}
                    setDataATM={setDataATM}
                  />
                </div>}

                <span id="cd_validation" className="cd-validation General-validationError General-hidden"></span>
                
                <button className="button cd-submitButton" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit Datum"} 
                </button>    
            </form>
      </div>
    </div>
  )
}

export default CreateDatum