import { Element } from 'react-scroll';
import './LandingResources.css';
import AuthorInitialSetupWalkthrough from '../../../resources/Author Initial Setup Walkthrough v1.docx';
import GroupInitialSetupWalkthrough from '../../../resources/Group Initial Setup Walkthrough v1.docx';

import React from 'react'
import { IoDocumentTextOutline } from 'react-icons/io5';
import { enText } from '../../../text/en';

const LandingResources = () => {

    const scrollToAnchor = (location) => {
        const anchor = document.querySelector('#' + location);
        anchor.scrollIntoView({behavior: 'smooth', block: 'start'}) 
    }

  return (
    

    <div className="LandingResources">
        <Element id='anchor_resources' name='anchor_resources' ></Element>
        <h2 className='landing-aboutHeader'>{enText.landingResources.header}</h2>
        <div className="landing-resources-container">
          <div className='landing-resource FlexColumn'>
              <h4>{enText.landingResources.authorWalkTitle}</h4>
              <span className='landing-resourceDesc'>{enText.landingResources.authorWalkDescription}</span>
              <a href={AuthorInitialSetupWalkthrough} download><IoDocumentTextOutline size={30} /></a>
          </div>
          <div className='landing-resource FlexColumn'>
              <h4>{enText.landingResources.groupWalkTitle}</h4>
              <span className='landing-resourceDesc'>{enText.landingResources.groupWalkDescription}</span>
              <a href={GroupInitialSetupWalkthrough} download><IoDocumentTextOutline size={30} /></a>
          </div>
        </div>
        <div className='landing-backToTop' onClick={()=>scrollToAnchor("anchor_landing_top")}>Back to Top</div>

    </div>
  )
}

export default LandingResources