const userFeedReducer = (
    state = {userFeed: null, loading: false, error: false}, 
    action
) => {
    // console.log("Before Switch");
    switch(action.type) {
        case "USER_FEED_START":
            return {...state, loading: true, error: false}
        case "USER_FEED_SUCCESS":
            return {...state, userFeed: action.data, loading: false, error: false}
        case "USER_FEED_FAIL":
            return {...state, loading: false, error: true}
        
        case "LOG_OUT":
            return {userFeed: null, loading: false, error: false}
        
        default: 
            return state
    }
}

export default userFeedReducer