import React, { useState } from 'react'
import './Profile.css'
import ProfileCard from '../../components/ProfileSide/ProfileCard/ProfileCard'
import Header from '../../components/Header/Header'
import EditProfile from '../../components/Profile/EditProfile/EditProfile'
import { useSelector } from 'react-redux'
import SetCompass from '../../components/Profile/SetCompass/SetCompass'
import CompassSettings from '../../components/ProfileLeft/Compass/CompassSettings/CompassSettings'
import InfoCard from '../../components/ProfileLeft/InfoCard/InfoCard'
import Compass from '../../components/ProfileLeft/Compass/Compass'

const Profile = () => {

  const {user} = useSelector((state) => state.authReducer.authData);

  const [profileContent, setProfileContent] = useState("card");
  
  
  const renderProfileComponent = () => {
    switch (profileContent) {
      case "card":
        return <ProfileCard location="profilePage"/>
      case "editProfile":
        return <EditProfile 
            user={user} 
            setProfileContent={setProfileContent}
          />
      case "setCompass":
        return <SetCompass 
            user={user} 
            setProfileContent={setProfileContent}
          />
      case "compassSettings":
        return <CompassSettings 
            user={user} 
            setProfileContent={setProfileContent} 
          />
    }
  }

  return (
    <div className="Profile HeaderGrid">
      <div>
        <Header />
      </div>
      <div className='BodyGrid'>
        <div className='profile-left'>
          <InfoCard setProfileContent={setProfileContent}/>
          {user.userType !== 1 && <Compass setProfileContent={setProfileContent}/>}
        </div>
        {renderProfileComponent()}
        <div></div>
      </div>
    </div>
  )
}

export default Profile