const itemFeedReducer = (
    state = {itemFeed: [], loading: false, error: false}, 
    action
) => {
    switch(action.type) {
        case "ITEM_FEED_START":
            return {...state, loading: true, error: false}
        case "ITEM_FEED_SUCCESS":
            // console.log(action.data);
            return {...state, itemFeed: action.data, loading: false, error: false}
        case "ITEM_FEED_FAIL":
            return {...state, loading: false, error: true}
        
            
        case "LOG_OUT":
            return {itemFeed: null, loading: false, error: false}

        default: 
            return state
    }
}

export default itemFeedReducer