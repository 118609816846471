import './LandingAbout.css';
import Video from '../../../img/20180322 _ Video 1 _ NS_Final Cut.m4v'
import React from 'react'
import { Element } from 'react-scroll';
import { enText } from '../../../text/en';
import { MdArrowForwardIos } from 'react-icons/md';

const LandingAbout = () => {

    const scrollToAnchor = (location) => {
        const anchor = document.querySelector('#' + location);
        anchor.scrollIntoView({behavior: 'smooth', block: 'start'}) 
    }

    const sectionExpand = (section) => {
        document.getElementById("landing_exp_" + section).classList.toggle("General-exp-arrow-con");
        document.getElementById("landing_" + section).classList.toggle("General-hidden");
    }

  return (
    <div className="LandingAbout">
        <Element id='anchor_about' name='anchor_about' ></Element>

        <div className='AppGeneralCard FlexColumn'>
            <h2 className='landing-aboutHeader'>{enText.landingAbout.whatIs_title}</h2>
            <span className='landing-aboutText'>{enText.landingAbout.whatIs_body}</span>
        </div>
        {/* <video className='landing-aboutVideo' src={Video} controls></video> */}
        
        <div className='AppGeneralCard FlexColumn'>
            <h2 className='landing-aboutHeader'>{enText.landingAbout.how_title}</h2>
            <span className='landing-aboutText'>{enText.landingAbout.how_body}</span>
        </div>

        <div className='AppGeneralCard FlexColumn'>
            <h2 className='landing-aboutHeader'>{enText.landingAbout.vision_title}</h2>
            <span className='landing-aboutText'>
                {enText.landingAbout.vision_body1}
                <ul>
                    <li>{enText.landingAbout.vision_body_li1}</li>
                    <li>{enText.landingAbout.vision_body_li2}</li>
                    <li>{enText.landingAbout.vision_body_li3}</li>
                    <li>{enText.landingAbout.vision_body_li4}</li>
                    <li>{enText.landingAbout.vision_body_li5}</li>
                </ul>
                {enText.landingAbout.vision_body2}
            </span>
        </div>

        <div className='AppGeneralCard FlexColumn'>
            <h2 className='landing-aboutHeader'>{enText.landingAbout.getThere_title}</h2>
            <span className='landing-aboutText'>
                <div>{enText.landingAbout.getThere_body1}</div>
                <Element id='anchor_kickoff' name='anchor_kickoff' ></Element>
                <div className='landing-subheading clickable' onClick={()=>sectionExpand("kickoff")}>
                    {enText.landingAbout.getThere_kickoff_title}
                    <MdArrowForwardIos id="landing_exp_kickoff" className='General-exp-arrow General-exp-arrow-con' />
                </div>
                <div id="landing_kickoff" className='General-hidden'>{enText.landingAbout.getThere_kickoff_body}</div>
                <Element id='anchor_phases' name='anchor_phases' ></Element>
                <div className='landing-subheading clickable' onClick={()=>sectionExpand("phases")}>
                    {enText.landingAbout.getThere_phases_title}
                    <MdArrowForwardIos id="landing_exp_phases" className='General-exp-arrow General-exp-arrow-con' />
                </div>
                <div id="landing_phases" className='General-hidden'><table className='landing-phaseTable'>
                    <tr><td className='landing-phaseTable-col1'>1</td>
                        <td className='landing-phaseTable-col2'>
                            <div className='landing-phaseBody'>
                                {enText.landingAbout.getThere_phase1}
                                <div className='landing-inset'>
                                    <div className='landing-phaseFeaturesHeader'><u>{enText.landingAbout.getThere_phaseFeatures_title}</u></div>
                                    <ul>
                                        <li>{enText.landingAbout.getThere_phase1_feature1}</li>
                                        <li>{enText.landingAbout.getThere_phase1_feature2}</li>
                                        <li>{enText.landingAbout.getThere_phase1_feature3}</li>
                                    </ul>

                                </div>
                                <div><b style={{color: "var(--primaryColor"}}>{enText.landingAbout.getThere_phase1_target}</b></div>
                            </div>
                        </td></tr>
                    <tr><td className='landing-phaseTable-col1'>2</td>
                        <td className='landing-phaseTable-col2'>
                            <div className='landing-phaseBody'>
                                {enText.landingAbout.getThere_phase2}
                                <div className='landing-inset'>
                                    <div className='landing-phaseFeaturesHeader'><u>{enText.landingAbout.getThere_phaseFeatures_title}</u></div>
                                    <ul>
                                        <li>{enText.landingAbout.getThere_phase2_feature1}</li>
                                        <li>{enText.landingAbout.getThere_phase2_feature2}</li>
                                        <li>{enText.landingAbout.getThere_phase2_feature3}</li>
                                    </ul>

                                </div>
                                <div><b style={{color: "var(--primaryColor"}}>{enText.landingAbout.getThere_phase2_target}</b></div>
                            </div>
                        </td></tr>
                    <tr><td className='landing-phaseTable-col1'>3</td>
                        <td className='landing-phaseTable-col2'>
                            <div className='landing-phaseBody'>
                                {enText.landingAbout.getThere_phase3}
                                <div><b style={{color: "var(--primaryColor"}}>{enText.landingAbout.getThere_phase3_target}</b></div>
                            </div>
                        </td></tr>

                </table></div>
            </span>
        </div>

        <div className='landing-backToTop' onClick={()=>scrollToAnchor("anchor_landing_top")}>Back to Top</div>
    </div>
  )
}

export default LandingAbout