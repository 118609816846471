import React from 'react'
import { AiOutlineBarChart } from 'react-icons/ai'
import { BsBookmarkCheck, BsEye } from 'react-icons/bs'
import { GiStamper } from 'react-icons/gi'
import { RiUserFollowLine } from 'react-icons/ri'
import { TbCompass } from 'react-icons/tb'
import { VscTypeHierarchy } from 'react-icons/vsc'
import './UserStats.css'

const UserStats = ( { user } ) => {
  return (
    <div className='UserStats FlexRow'>
        {user.userType === 1 
          ? <>
              <div className="FlexRow uv-info"><div><VscTypeHierarchy /></div><div>{user.items.length}</div></div>
              <div className="FlexRow uv-info"><div><AiOutlineBarChart /></div><div>{user.data.length}</div></div> 
              </>
          : ""
        }

        {user.userType === 0 || user.userType === 2 
          ? <div className="FlexRow uv-info"><div><TbCompass /></div><div>{user.compass.filter((p)=>p.disposition !== null).length}</div></div>
          : ""
        }

        {user.userType === 1 || user.userType === 2
          ? <>
              <div className="FlexRow uv-info">
                <div><GiStamper/></div><div>{user.userType === 2 ? user.stamps.length : user.stamped}</div>
              </div>
              <div className="FlexRow uv-info">
                <div><BsEye/></div><div>{user.userType === 2 ? user.views.length : user.viewed}</div>
              </div>
            </>
          : ""
        }
        
        {user.userType === 0 || user.userType === 2 
          ? <div className="FlexRow uv-info"><div><RiUserFollowLine/></div><div>{user.follow.length}</div></div>
          : ""
        }

        {user.userType === 1 || user.userType === 2 
          ? <div className="FlexRow uv-info"><div><BsBookmarkCheck/></div><div>{user.subscribe.length}</div></div>
          : ""
        }
    </div>
  )
}

export default UserStats