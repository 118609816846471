import { useDispatch, useSelector } from 'react-redux'
import './DatumView.css'

import React, { useEffect, useState } from 'react'
import { FiBox } from 'react-icons/fi'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import { GiStamper } from 'react-icons/gi'
import { BsEye } from 'react-icons/bs'
import { TbCompass } from 'react-icons/tb'
import { BiHash, BiPen } from 'react-icons/bi'
import { stampDatum, viewDatum } from '../../../../actions/DatumAction'
import { VscTypeHierarchy } from 'react-icons/vsc'
import Reference from '../../CreateDatum/AddReferenceModal/Reference/Reference'
import { fetchModalUser } from '../../../../actions/ModalAction'
import { Tabs } from '@mantine/core'
import { triggerItem } from '../../../../actions/ItemAction'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import Relation from '../../CreateDatum/AddRelationModal/Relation/Relation'
import { DemoIds } from '../../../../Data/DemoIds';
import { generateRelationIcon } from '../../../../tools/generateRelationIcon'


const DatumView = ( { 
    data, 
    stampedProp, 
    setStampedProp, 
    stampsProp, 
    setStampsProp,
    viewedProp, 
    setViewedProp,
    viewsProp,
    setViewsProp,
    triggersProp,
    setTriggersProp
 } ) => {

    const {user} = useSelector((state) => state.authReducer.authData)
    const userImgPath = process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage;
    const dispatch = useDispatch();
    const [stamped, setStamped] = useState(stampedProp);
    const [stamps, setStamps] = useState(stampsProp);
    const [viewed, setViewed] = useState(viewedProp);
    const [views, setViews] = useState(viewsProp);
    const [triggers, setTriggers] = useState(triggersProp);

    const datumContainerClass = () => {
        var classText = 'Datum';
        if (stamped) {classText += ' stampedDatum'}
        if (viewed) {classText += ' viewedDatum'}
        return classText;
    }

    const handleStamp = () => {
        
        const stampLogic = () => {
            setStamped((prev) => !prev)
            setStampedProp((prev) => !prev)
            if (stamped) {
                setStamps((prev)=> prev - 1); 
                setStampsProp((prev)=> prev - 1);
                for (let i = 0; i < data.references.length; i++) {
                    if (document.getElementById("triggered_value_" + i)) {
                        console.log(document.getElementById("triggered_value_" + i));
                        document.getElementById("triggered_value_" + i).style.display = "none";
                        console.log(document.getElementById("triggered_value_" + i));
                    }
                }
                setTriggers(0);
                setTriggersProp(0);
                // setTriggerDetails(null);
                // reset initial details object / state 
            } else {
                setStamps((prev)=> prev + 1);
                setStampsProp((prev)=> prev + 1);   
            }
            console.log(data._id);
            console.log(user._id);
            dispatch(stampDatum(data._id, user._id));
            handleView();
        }

        // Demo - remove later, and go back to only stampLogic block
        if (user.isAdmin) {
            // Admin may stamp any data
            stampLogic();
        } else if (data.isAdmin) {
            // Non-admin may not stamp admin data
        } else {
            // Non-admin stamping live datum - go ahead
            stampLogic();
        }
    }

    const handleView = () => {
        console.log("Inside handleView")
        if (viewed == false) {
            setViews((prev)=> prev + 1);
            setViewsProp((prev)=> prev + 1);
            dispatch(viewDatum(data._id, user._id));
        }
        setViewed(true);
        setViewedProp(true);
        // document.getElementById("datum_container_" + id).classList.add("viewedDatum");
        document.getElementById("datum_modal_view_button").disabled = true;
    }

    const createdDate = () => {
        const date = new Date(data.createdAt);
        return (date.toDateString());
    }

    const handleTrigger = (id) => {
        
        const el = document.getElementById("dv_dropdown_" + id);

        const stampIndex = user.stamps.findIndex((stamp)=>stamp.datumId===data._id);

        console.log("Start Decode Block");
        console.log("Stamps Array:");
        console.log(user.stamps);
        console.log("Triggers Array:");
        console.log(user.stamps[stampIndex].triggers);
        if (user.stamps[stampIndex].triggers.findIndex((trig)=>trig.item===data.references[id]._id) === -1) {
            document.getElementById("open_trigger_" + id).classList.toggle("conflictIcon");
            setTriggers((prev) => prev + 1);
            setTriggersProp((prev) => prev + 1);
        }
        dispatch(triggerItem(user._id, data._id, el.value, data.references[id]._id))
        const container = document.getElementById("triggered_value_" + id);
        container.innerHTML = "<div class='dv-triggered-value'>" + user.compass[el.value].value + "</div>";
    }

    const generateValueList = (triggerId) => {
        var output = '<select id="dv_dropdown_' + triggerId + '" class="dv-value-dropdown">' +
                        '<option value="" selected disabled hidden>Choose the value triggered by this reference.</option>';
        for (let i = 0; i < user.compass.length; i++) {
            const newString = '<option value="' + i + '">' + user.compass[i].value + '</option>';
            output += newString;
        }       
        output += '</select>'
        return output;
    }

    const toggleSetTrigger = (triggerId) => {
    
        const trigValContainer = document.getElementById("triggered_value_" + triggerId);
    
        // Original:
        if (trigValContainer.style.display === "none") {
            trigValContainer.style.display = "block";
            
            // - I'm almost certain this is horribly vulnerable to injection
            trigValContainer.innerHTML = generateValueList(triggerId);
            document.getElementById("dv_dropdown_" + triggerId).addEventListener("change", ()=>handleTrigger(triggerId));
        } else {
            if (!document.getElementById("dv_dropdown_" + triggerId)) {
                document.getElementById("open_trigger_" + triggerId).classList.toggle("conflictIcon");
                dispatch(triggerItem(user._id, data._id, null, data.references[triggerId]._id));
                // need to mirror what's going on (and currently isn't working) above with adding to trigger count and styling compass
                setTriggers((prev) => prev - 1);
                setTriggersProp((prev) => prev - 1);
            }
            trigValContainer.style.display = "none";
        }
    }

    const loadGroupTriggers = () => {
        const stampIndex = user.stamps.findIndex((stamp)=>stamp.datumId===data._id);
        if (stampIndex > -1) {
            if (user.stamps[stampIndex].triggers) {
                const triggers = user.stamps[stampIndex].triggers
                for (let t = 0; t < triggers.length; t++) {
                    for (let r = 0; r < data.references.length; r++) {
                        // Check if the reference item is present in the triggers array
                        if (triggers[t].item === data.references[r]._id) {
                            const trigValContainer = document.getElementById("triggered_value_" + r);
                            trigValContainer.style.display = "block";
                            trigValContainer.innerHTML = "<div class='dv-triggered-value'>" + user.compass[triggers[t].value].value + "</div>";
                            document.getElementById("open_trigger_" + r).classList.toggle("conflictIcon");
                        }
                    }
                }
            }
        }
    }
    
    const loadGeneralTriggers = () => {
        // Got the groups.triggers array loaded!  Now let's put it to work
        for (let r = 0; r < data.references.length; r++) {
            let markup = "<div class='dv-references'>";
            let count = 0;
            for (let g = 0; g < data.stampDetails.length; g++) {
                if (data.stampDetails[g].triggers) {
                    for (let t = 0; t < data.stampDetails[g].triggers.length; t++) {
                        if (data.stampDetails[g].triggers[t].item === data.references[r]._id) {
                            console.log("Inside triggered id = reference id");
                            markup +=   "<div class='FlexRow dv-reference'><div class='dv-group-img-container'><img src='" + process.env.REACT_APP_PUBLIC_FOLDER + data.stampDetails[g].groupImage + "'/></div>" +
                                        "<div class='dv-triggered-value'>" + data.stampDetails[g].triggers[t].valueText + "</div></div>";
                            count++;
                        }
                    }
                }
            }
            markup += "</div>";
            if (count > 0) {
                const trigValContainer = document.getElementById("triggered_value_" + r);
                trigValContainer.style.display = "block";
                trigValContainer.innerHTML = markup;
            }
        }
    }

    // This variable setup was necessary for useEffect development re-rendering
    var triggersLoaded = false;
    useEffect(()=> {

        console.log("Inside UseEffect")
        // setTriggerDetails(initialTriggerDetails())
        const loadTriggersByUserType = () => {
            switch (user.userType) {
                case 0:
                    loadGeneralTriggers();    
                break;
                case 1:
                    break;
                case 2:
                    console.log("Inside Case 2");
                    loadGroupTriggers();
                    break;
            }
            triggersLoaded = true;
        }
        if (triggersLoaded === false) {
            loadTriggersByUserType();
        }
        // return () => loadTriggersByUserType();
    }, []);

    const loadUser = () => {
        const logDetails = {
            userId: user._id,
            username: user.username,
            parent_datumId: data._id,
            parent_datumText: data.datumText
        }
        dispatch(fetchModalUser(data.authorId, logDetails))
    }

    const prepareSourceMarkup = (source, index) => {
        // console.log("Preparing Source Markup with index: " + index);
        return (
          <div className='cd-asm-container FlexRow'>
            <a href={source.link} target="_blank" rel="noopener noreferrer">
                <div className="cd-source-info">
                <div className='cd-asm-text'>{source.text}</div>
                <div className='cd-asm-link'>{source.link}</div>
                <div className='cd-asm-description'>{source.description}</div>
                </div>
            </a>
          </div>
        )
      }

    

  return (
        <div 
            className={datumContainerClass()}
        >
            <div className="datumBody">
                <div className="generalBar">
                    {/* <AiOutlineBarChart size="1rem"/> */}
                    {data.datumText !== '' && <span className='datumText'>
                        {data.datumText}
                    </span>}
                    {data.datumText === '' && <span className='dl-relations FlexColumn'>
                        {data.relations.map((r)=>{
                            return(
                                <div className="FlexRow dl-relationRow">
                                    <span className="dl-endIcon FlexRow">
                                        {generateRelationIcon(r.relation)}
                                    </span>
                                    <div className='dl-vl'></div>   
                                    <span className="dl-relation">
                                        <Relation 
                                            relation={r} 
                                            references={data.references}
                                        />
                                    </span>
                                </div>
                        )})}
                        
                    </span>}
                </div>
                <div className="generalBar attributes">
                    <FiBox/>
                    <span className="referenced">{data.references.length}</span>
                    <HiArrowsRightLeft />
                    <span className="relations">{data.relations.length}</span>
                    <MdOutlineAssignmentTurnedIn/>
                    <span className="sources">{data.sources.length}</span>
                    <GiStamper style={stamped?{color: 'var(--primaryColor)'}:{color: 'gray'}}/>
                    <span className="stamps">
                        {stamps}
                    </span>
                    <BsEye style={viewed?{color: 'var(--primaryColor)'}:{color: 'gray'}}/>
                    <span className="views">
                        {views}
                    </span>
                    {user.userType !== 1
                        ? <>
                            <TbCompass className={triggers > 0 ? 'conflictIcon' : ''}/>
                            <span className="conflictCount">{triggers ? triggers : "0"}</span>
                        </>
                        : ""
                    }
                </div>
            </div>
            <hr/>

            

            <Tabs radius="md" defaultValue="references">
              <Tabs.List grow>
                <Tabs.Tab value="references" icon={<VscTypeHierarchy/>}></Tabs.Tab>
                <Tabs.Tab value="relations" icon={<HiArrowsRightLeft/>}></Tabs.Tab>
                <Tabs.Tab value="sources" icon={<MdOutlineAssignmentTurnedIn/>}></Tabs.Tab>
                <Tabs.Tab value="author" icon={<BiPen size="1rem" />}></Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="references" pt="xs">
                <div className='FlexRow dv-references-container'>
                    <div id='dv_references' className='dv-references'>
                    {data.references.map((reference, _id) => {
                        // console.log(reference)
                        // console.log("id: " + _id)
                        const parentDatumObj = {
                            parent_datumId: data._id,
                            parent_datumText: data.datumText
                        }
                        return (
                            <>
                                <div className='FlexRow dv-reference' key={_id}>
                                    <Reference 
                                        reference={reference} 
                                        id={_id}
                                        location="datumView"
                                        parentDatumObj={parentDatumObj}
                                    />
                                    {user.userType === 2 && stamped === true 
                                        ? <TbCompass 
                                            onClick={()=>toggleSetTrigger(_id)}
                                            id={"open_trigger_" + _id}
                                            /> : 
                                        ""
                                    }
                                </div>
                                <div 
                                    className='dv-triggered-value-container' 
                                    style={{display: "none"}}
                                    id={"triggered_value_" + _id}
                                ></div>
                            </>
                        )
                    })}
                    </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="relations" pt="xs">
                <div className='FlexRow dv-relations-container'>
                    <div id='dv_relations' className='dv-relations'>
                    {data.relations.length > 0 ? data.relations.map((relation, _id) => {
                        return (
                            <div className='FlexRow dv-relation' key={_id}>
                                <Relation 
                                    relation={relation} 
                                    id={_id}
                                    references={data.references}
                                />
                            </div>
                        )
                    })
                    : "This datum does not have any relations."
                    } 
                    
                    </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="sources" pt="xs">
                <div className="dv-sources-container">
                    {data.sources.length == 0 ? "This datum does not have any sources attached." : 
                            data.sources.map((source, index) => {
                            // console.log(source)
                            return (prepareSourceMarkup(source, index))
                    })}
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="author" pt="xs">
              <div className="bottom-bar FlexRow">
                <div className="datumDetails">
                    <div className="generalBar authorBar" onClick={loadUser}>
                        <BiPen/>
                        
                        <img src={userImgPath} alt="" className='authorBarImg' />
                        <span className="datumAuthor">
                            @{data.authorName}
                        </span>
                        <span className="timestamp">{createdDate()}</span>
                    </div>
                    
                    {(data.themes.length > 0) && <div className="generalBar themesBar">
                        <BiHash/>
                        <span className="themes">
                            {data.themes.map((trend, id) => {
                                return (
                                    <span className='theme'>
                                        <a href='#'>
                                            #{trend}&nbsp;&nbsp; 
                                        </a>
                                    </span>
                                )
                            })}
                            
                        </span>
                        
                    </div>}
                </div>
                
            </div>
              </Tabs.Panel>
            </Tabs>



            

            <hr/>
            <div className="dv-bottom-bar">
                {user.userType === 2 
                        ? <div className="mark-buttons FlexRow">
                            <GiStamper id={"datum_modal_stamp_button"} className={stamped? 'button-marked' : 'button-unmarked'} onClick={handleStamp}/>
                            <BsEye id={"datum_modal_view_button"} className={viewed? 'button-marked' : 'button-unmarked'} onClick={handleView}/>
                        </div>
                        : ""
                }
            </div>

            

        </div>




  )
}

export default DatumView