import { useDispatch, useSelector } from 'react-redux'
import './CompassSettings.css'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import React, { useEffect, useState } from 'react'
import { updateTransferrence } from '../../../../actions/UserAction';
import { RxTarget } from 'react-icons/rx'
import { CgSpaceBetween } from 'react-icons/cg'

const CompassSettings = () => {
  
  const {user} = useSelector((state)=>state.authReducer.authData);

  const [transferrenceSettings, setTransferrenceSettings] = useState(null)
  const dispatch = useDispatch();
    

    useEffect(()=> {
        setTransferrenceSettings(user.relations);
    }, [])

    const handleTransferrenceIncrement = (i) => {
        if (transferrenceSettings[i].transferrence === 0) {
            // adjust authReducer and server call
            setTransferrenceSettings([...transferrenceSettings.map((relation, id)=> id === i ? {...relation, transferrence: relation.transferrence + 1} : relation)])
        } else {
            console.log("Maximum reached")
            // make alert
        }
    }

    const handleTransferrenceDecrement = (i) => {
        if (transferrenceSettings[i].transferrence === 1) {
            console.log(i);
            // adjust authReducer and server call
            setTransferrenceSettings([...transferrenceSettings.map((relation, id)=> id === i ? {...relation, transferrence: relation.transferrence - 1} : relation)])

        } else {
            console.log("Minimum reached");
            // make alert
        }
    }

    const handleTransferrenceSubmit = () => {
        dispatch(updateTransferrence(user._id, transferrenceSettings));
    }
  
    return (
    <div className="CompassSettings">
        <h2>Compass Settings</h2>
        <span className="FlexRow">
            <h3>Transferrence</h3>&nbsp;
            <CgSpaceBetween />
        </span>

        <div className="cs-instructions">
            Adjust the values below to set the scope for triggering transferrance.
        </div><br/>
        <div>
            Transferrence is set for each relation, and indicates whether there’s scope required 
            between two items to transfer triggering.  If the transferrence for a relation is 0, 
            then a trigger in one item is not transferred to items with relations.  
            If the transferrence is 1, then a trigger is transferred to all related items as if 
            the related items had triggered the value.  Note that the transferrence value for 
            "replaces" is fixed at -1, as this relation is used in a functionally unique way.
        </div><br/>
        <div className="cs-settings">
            
            {transferrenceSettings && transferrenceSettings.map((relation, id)=> {
                if (relation.relation !== "replaces") {
                    return (
                        <div className='FlexRow'>
                            <div>
                                {relation.relation}:
                            </div>
                            &nbsp;
                            <IoIosArrowDown onClick={()=>handleTransferrenceDecrement(id)}/>
                            <div>
                                {relation.transferrence}
                            </div>
                            <IoIosArrowUp onClick={()=>handleTransferrenceIncrement(id)}/>
                        </div>
                    ) 
                }
            })}
        </div>
        <button className='button' onClick={handleTransferrenceSubmit}>Submit</button>
        <br/><br/>
        <span className="FlexRow">
            <h3>Scope</h3>&nbsp;
            <RxTarget/>
        </span>
        <div className="cs-instructions">
            Adjust the scope value below.
        </div><br/>
        <div>
            Scope is set for each value, and indicates how many degrees of association will be considered for transferring a trigger between items for which the relation between them does not have transferrence.
        </div><br/>
        <div className='FlexRow'>
            <IoIosArrowDown />
            <div>
                1
            </div>
            <IoIosArrowUp />
        </div>
        <div className="cs-instructions">
            Scope is currently locked at 1.
        </div><br/>
    </div>
  )
}

export default CompassSettings