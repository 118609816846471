import React, { useEffect } from 'react'
import './FollowCard.css'
import './FollowCard.css'
import UserList from '../../User/UserList'
import { useDispatch, useSelector } from 'react-redux'
import { getActionFeed } from '../../../actions/UserFeedAction'
import { Element } from 'react-scroll'

const FollowCard = ( { activeType, followDetails } ) => {
  
  const { user } = useSelector((state) => state.authReducer.authData);
  const userAction = followDetails.action;
  const {actionFeed, loading: actionFeedLoading} = useSelector((state)=> state.actionFeedReducer)
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (followDetails.action) {
      dispatch(getActionFeed(user._id, followDetails.action));
    }
  }, [followDetails, activeType]) 

  return (
    <div className="AppGeneralCard">
      <div className="FollowListHeader">
        {followDetails.headerText}<div className={actionFeedLoading ? 'General-loading-circle' : ''}></div>
      </div>
      <div className='fc-list-container'>
        {actionFeed && actionFeed[userAction] &&        
        actionFeed[userAction].length > 0 
          ? (actionFeed[userAction].map ((mappedUser, id) => {
              if (mappedUser._id !== user._id) {
                return (  
                  <UserList key={`actionFeed_${id}`} data={mappedUser} />
                )
              }
            }))
          : <div className='fc-empty-container'>
              {actionFeedLoading 
              ? "Loading..."
              : <>
                  <div className='fc-empty1'>{followDetails.emptyText1}</div>
                  <div className='fc-empty2'>{followDetails.emptyText2}</div>
                </>
              }
            </div>
        }
      </div>
      <Element id='anchor_browse' name='anchor_browse' className='anchor-position-offset'></Element>
    </div>
  )
}

export default FollowCard