import axios from "axios"

const API = axios.create();

export const uploadImage = (data) => {
      
    API.post(process.env.REACT_APP_MEDIA_UPLOAD_URL, data)
    .then((response)=>console.log(response))
    .catch((error)=>{
        console.log(error)
    });
}