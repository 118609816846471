import React, { useEffect, useState } from 'react'
import './DataFeed.css'
import { useDispatch, useSelector } from 'react-redux'
import { getDataFeed } from '../../../actions/DataFeedAction'
import DatumList from './Datum/DatumList'
import { fetchThemesAutocomplete } from '../../../api/ThemeManipulation'
import { AiOutlineBarChart } from 'react-icons/ai'
import { LiaTimesSolid } from 'react-icons/lia'
import { fetchItemsAutocomplete } from '../../../api/ItemManipulation'
import Reference from '../CreateDatum/AddReferenceModal/Reference/Reference'

const DataFeed = () => {
  
  const dispatch = useDispatch();
  const {user} = useSelector((state)=> state.authReducer.authData);
  const {loading: authDataLoading} = useSelector((state)=> state.authReducer);
  const {dataFeed, loading: dataFeedLoading} = useSelector((state)=> state.dataFeedReducer);
  const [displayThemes, setDisplayThemes] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [displayReferences, setDisplayReferences] = useState(null);
  const [selectedReference, setSelectedReference] = useState(null);

  const filtersObj = {
    group: null,
    authorName: null,
    keyword: null,
    subscribed: false,
    followed: false,
    theme: null,
    reference: null
  }
  const [filters, setFilters] = useState(filtersObj);

  useEffect(()=> {
    if (!authDataLoading) {
      dispatch(getDataFeed(user._id, null));
    }
  },[authDataLoading])

  const itemAutocomplete = async() => {
    // I think at some point I'll want to eliminate fetching the entire links and adoptions arrays,
    // both for privacy and efficiency.  Instead, I can just return a length value for links and 
    // a length and index number for user (if applicable) for adoptions
    const inputEl = document.getElementById("itemSearchARM");
    const value = inputEl.value;
    if (value.length > 2) {

      const res = await fetchItemsAutocomplete(value);
      const data = res.data;
          
      data && setDisplayReferences(
        data.map((reference, id) => {
          
          return (
            <Reference 
              reference={reference} 
              id={id} 
              key={id}
              location="dataFeedFilter"
              loaded={false}
              setSelectedReference={setSelectedReference}
              setDisplayReferences={setDisplayReferences}
            />
          )
        })
      )
    } else {
      setDisplayReferences(null);
    }
  }

  const themeAutocomplete = async() => {
    const inputEl = document.getElementById("themeSearch");
    const value = inputEl.value;
    console.log(value);
    if (value.length > 2) {

      const res = await fetchThemesAutocomplete(value);
      const data = res.data;
      console.log(data);
          
      data && setDisplayThemes(
        data.map((theme, _id) => {
          
          return (
            <div className='atm-result' onClick={()=>selectTheme(theme)}>
                <div className="atm-result-theme">
                    {theme.theme}
                </div>
                <div className="atm-result-stats">
                    <AiOutlineBarChart/>
                    {theme.data.length}
                </div>
            </div>
          )
        })
      )
    } else {
      setDisplayThemes(null);
    }
  }

  const selectTheme = (theme) => {
    setDisplayThemes(
        <span className='FlexRow'>
            <div className='df-selected-theme'>
                <div className="atm-result-theme">
                    {theme.theme}
                </div>
                <div className="atm-result-stats">
                    <AiOutlineBarChart/>
                    {theme.data.length}
                </div>
            </div>
            <LiaTimesSolid onClick={removeTheme}/>
        </span>
    )
    // setDisplayThemes(null);
    document.getElementById("themeSearch").value = '';
    setFilters({...filters, theme: theme._id})
  }   

  const removeTheme = () => {
    setDisplayThemes(null);
    setFilters({...filters, theme: null})
  }

  const handleChange = (e) => {
    setFilters({...filters, [e.target.name]: e.target.value})
  }

  const handleChecked = (e) => {
    setFilters({...filters, [e.target.name]: e.target.checked})
  }

  const handleSubmitFilters = (e) => {
    e.preventDefault();
    dispatch(getDataFeed(user._id, JSON.stringify(filters)))
  }


  return (
    <>
      <div className="General-filter-bar General-hidden" id="dataFeed_filter_bar">
        {/* 0: FILTER by group -- PUNTED */}
        {/*  */}
        
        
        {/* 2: FILTER by author */}
        {/* 2: FILTER by keyword */}
        {/* 2: FILTER by item reference */}
        {/* 2: FILTER by subscribed */}
        {/* 2: FILTER by stamped/unstamped DEFAULT OMIT STAMPED */}
        {/* 2: FILTER By theme */}
        {/* 1: FILTER by stamped/unstamped */}


        <form action="" className='FlexColumn General-filter-form' onSubmit={handleSubmitFilters}>
          
          {/* Punted */}
          {/* {user.userType === 2 && 
            <span className='FlexRow General-filter-form'>
                <span className='General-filter-key'>Author:</span>
                <input type="text" placeholder="Filter by author..." name="authorName" className='General-input' onChange={handleChange}/>
            </span>
          } */}
          
          <span className='FlexRow General-filter-form'>
              <span className='General-filter-key'>Keyword:</span>
              <input type="text" placeholder="Filter by keyword..." name="keyword" className='General-input' onChange={handleChange}/>
          </span>
          
          {/* Punted */}
          {/* {user.userType !== 0 &&
            <span className='FlexRow General-filter-form'>
                <span className='General-filter-long-key'>Include Unsubscribed:</span>
                <input id="subscribed" name="subscribed" type="checkbox" onClick={handleChecked}/>
            </span>
          } */}
          
          {/* Punted */}
          {/* {user.userType !== 0 &&
            <div className='FlexRow General-filter-form'>
              <span className='FlexRow General-filter-form'>
                  <span className='General-filter-long-key'>Omit Stamped:</span>
                  <input id="stamped" name="stamped" type="checkbox" defaultChecked onClick={handleChecked}/>
              </span>
              <span className='FlexRow General-filter-form'>
                  <span className='General-filter-long-key'>Omit Unstamped:</span>
                  <input id="unstamped" name="unstamped" type="checkbox" onClick={handleChecked}/>
              </span>
            </div>
          } */}
          <div className='FlexColumn General-filter-form'>
            <span className='FlexRow General-filter-form'>
              {/* This key span width is not lining up with the rest and I don't know why */}
              <span className='General-filter-key'>Theme:</span>
              <input id="themeSearch" onChange={themeAutocomplete} name="themeSearch" type="text" placeholder="Filter by theme..." />
            </span>
            <div className='df-themes-container'>{displayThemes}</div>
          </div>
          
          {/* Punted */}
          {/* {user.userType === 2 &&
            <div className='FlexColumn General-filter-form'>
              <span className='FlexRow General-filter-form'>
                <span className='General-filter-key'>Item:</span>
                <div className="Search arm-search">
                  <input id="itemSearchARM" onChange={itemAutocomplete} name="itemSearchARM" type="text" placeholder="Filter by item..." />
                </div>
              </span>
              <div id="arm_content" className="arm-content">{displayReferences}</div>
            </div>
          } */}





          
          <button className="button signupButton" type="submit" >
              Apply Filters 
          </button>    
      </form>
        
        
    
      </div>
      <div className="DataFeed">
        {dataFeed && dataFeed.length > 0
          ? dataFeed.map((datum, id) => {
            return <DatumList data={datum} id={datum._id} key={datum._id}/>
          })
          : <div className='UserFeed'>
            {dataFeedLoading 
              ? "Loading..." 
              : "No data match your filter criteria.  Try following more groups or removing some filters."
            }
          </div>
        }
      </div>
    </>
  )
}

export default DataFeed