import React, { useState } from 'react'
import CreateDatum from './CreateDatum/CreateDatum'
import { CreateItem } from './CreateItem/CreateItem'
import CreateBar from './CreateBar/CreateBar'
import './Main.css'
import UserFeed from './UserFeed/UserFeed'
import DataFeed from './Feed/DataFeed'
import MainBar from './MainBar/MainBar'
import { useSelector } from 'react-redux'
import CategoryBrowse from './CategoryBrowse/CategoryBrowse'
import ItemFeed from './ItemFeed/ItemFeed'

const Main = () => {

  const [mainContent, setMainContent] = useState({current: "itemFeed", previous: "itemFeed"});
  const {user} = useSelector((state)=>state.authReducer.authData);


  var userFeedType;
  var userFeedText;
  switch (user.userType) {
    case 0:
      userFeedType = 2;
      userFeedText = "Group";
      break;
    case 1:
      userFeedType = 2;
      userFeedText = "Group";
      break;
    case 2:
      userFeedType = 1;
      userFeedText = "Author";
      break;
  }

  const renderContentComponent = () => {
    switch (mainContent.current) {
      case "itemFeed":
        return <ItemFeed />;
      case "dataFeed":
        return <DataFeed />;
      case "categoryBrowse":
        return <CategoryBrowse />;
      case "userFeed":
        return <UserFeed userFeedType = {userFeedType}/>;
      case "c_item":
        return (
          <CreateItem 
            setMainContent = {setMainContent}
          />
        )
      case "c_datum":
        return (
          <CreateDatum 
            setMainContent = {setMainContent}
          />
        );
    }
  }

  return (
    <div className='Main'>
        <MainBar
          mainContent = {mainContent}
          setMainContent = {setMainContent}
          userFeedType = {userFeedType}
          userType = {user.userType}
          userFeedText = {userFeedText}
        />
        {renderContentComponent()}
    </div>
  )
}

export default Main