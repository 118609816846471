import * as ItemApi from '../api/ItemManipulation'

export const submitItem = (formData) => async(dispatch) => {
    dispatch({type: "ITEM_SUBMIT_START"})
    try {
        const {data} = await ItemApi.submitItem(formData)
        dispatch({type: "ITEM_SUBMIT_SUCCESS", data: data})
        dispatch({type: "USER_ITEM_INCREMENT", data: data})
    } catch (error) {
        dispatch({type: "ITEM_SUBMIT_FAIL"})
    }
}

export const triggerItem = (userId, datumId, value, itemId) => async(dispatch) => {
    
    dispatch({type: "ITEM_TRIGGER_START"})
    try {
        console.log("Inside Try")
        const {data} = await ItemApi.triggerItem(userId, datumId, value, itemId)
        console.log(data);
        dispatch({type: "ITEM_TRIGGER_SUCCESS", data: {userId: userId, datumId: datumId, value: value, itemId: itemId}})
    } catch (error) {
        console.log(error)
        dispatch({type: "ITEM_TRIGGER_FAIL"})
    }
}

export const getItemFeed = (id, filters)=> async(dispatch)=> {
    dispatch({type: "ITEM_FEED_START"})
    try {
        const {data} = await ItemApi.getItemFeed(id, filters);
        dispatch({type: "ITEM_FEED_SUCCESS", data: data});
    } catch (error) {
        dispatch({type: "ITEM_FEED_FAIL"})
        console.log(error);
    }
}

// I may eventually want to separate this into its own reducer
export const getCategoryFeed = (id, itemId, nav, filters)=> async(dispatch)=> {
    dispatch({type: "ITEM_FEED_START"})
    try {
        console.log(nav)
        const {data} = await ItemApi.getCategoryFeed(id, itemId, filters);
        dispatch({type: "ITEM_FEED_SUCCESS", data: {itemFeed: data, nav: nav}});
    } catch (error) {
        dispatch({type: "ITEM_FEED_FAIL"})
        console.log(error);
    }
}