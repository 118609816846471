import './App.css'
import Home from './pages/home/Home'
import Profile from './pages/Profile/Profile'
import Auth from './pages/Auth/Auth'
import {Routes, Route, Navigate} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MantineProvider } from '@mantine/core'
import { Element } from 'react-scroll'
import Landing from './pages/Landing/Landing'
import Logs from './pages/Logs/Logs'

function App() {

  const user = useSelector((state)=>state.authReducer.authData?.user);
  
  return (
    <div className="App">
        <Element id='anchor_top' name='anchor_top' ></Element>
      
        <Routes>
          <Route 
            path='/' 
            element={user ? <Navigate to="home" /> : <Navigate to="landing" />}
          />
          <Route 
            path='/landing' 
            element={<Landing/>}
          />
          <Route 
            path='/home' 
            element={user ? <Home /> : <Navigate to="../auth" />}
          />
          <Route 
            path='/auth' 
            element={user ? <Navigate to="../home" /> : <Auth />}
          />
          <Route 
            path='/profile/:id' 
            element={user ? <Profile/> : <Navigate to="../landing" />}
          />
          <Route 
            path='/logs' 
            element={user && user.isAdmin ? <Logs/> : <Navigate to="../home" />}
          />
        </Routes>
      
    </div>
  )
}

export default App

