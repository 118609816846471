const authReducer = (
    state = { authData: null, loading: false, error: null, message: null}, 
    action
)   => {
        switch(action.type) {
            case "AUTH_START":
                return{...state, loading: true, error: null};
            case "AUTH_SUCCESS":
                localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return{...state, authData: action.data, loading: false, error: null};
            case "AUTH_FAIL":
                if (action.status.response !== undefined) {
                    return{...state, loading: false, error: action.status.response.status};
                } else {
                    return{...state, loading: false, error: "A server error has occurred.  Please try again later."};
                }

            case "AUTH_UPDATE_PASS_START":
                return{...state, loading: true, error: null, message: null};
            case "AUTH_UPDATE_PASS_SUCCESS":
                return{...state, loading: false, error: null, message: "Password updated successfully."};
            case "AUTH_UPDATE_PASS_FAIL":
            console.log(action.status);
            if (action.status !== undefined) {
                    return{...state, loading: false, error: action.status};
                } else {
                    return{...state, loading: false, error: "A server error has occurred.  Please try again later."};
                }
            case "AUTH_REFRESH_START":
                return{...state, loading: true, error: null, message: null};
            case "AUTH_REFRESH_SUCCESS":
                return{...state, authData: action.data, loading: false, error: null};
            case "AUTH_REFRESH_FAIL":
                return{...state, loading: false, error: true};

            case "AUTH_CLEAR_ERROR":
                return{...state, loading: false, error: null, message: null};
            
            case "UPDATE_USER_START":
                return{...state, loading: true, error: null}
            case "UPDATE_USER_SUCCESS":
                localStorage.setItem('profile', JSON.stringify({...action?.data}))
                return{...state, authData: action.data, loading: false, error: null}
            case "UPDATE_USER_FAIL":
                return{...state, loading: false, error: true}

            case "UPDATE_TRANSFERRENCE_START":
                return{...state, loading: true, error: false}
            case "UPDATE_TRANSFERRENCE_SUCCESS":
                return{...state, authData: {...state.authData, user: {...state.authData.user, relations: [...action.data]}}, loading: false, error: false}
            case "UPDATE_TRANSFERRENCE_FAIL":
                return{...state, loading: false, error: true}

            case "FOLLOW_USER_START":
                if (!state.authData.user.follow.includes(action.details.user._id)) {
                    return{...state, authData: {...state.authData, user: {...state.authData.user, follow: [...state.authData.user.follow, action.details.user._id], compass: [...state.authData.user.compass, ...action.details.compass]}}, loading: true, error: false}
                } else {
                    return{...state, authData: {...state.authData, user: {...state.authData.user, follow: [...state.authData.user.follow.filter((id)=>id!==action.details.user._id)], compass: [...state.authData.user.compass.filter((value)=>value.group!==action.details.user._id)]}}, loading: true, error: false}
                }
            case "FOLLOW_USER_SUCCESS":
                console.log(action.data);
                return{...state, loading: false, error: null}
            case "FOLLOW_USER_FAIL":
                return{...state, loading: false, error: true}

            case "SUBSCRIBE_USER_START":
                console.log("Inside SUBSCRIBE_USER_START");
                if (!state.authData.user.subscribe.includes(action.details.user._id)) {
                    console.log("Inside if")
                    return{...state, authData: {...state.authData, user: {...state.authData.user, subscribe: [...state.authData.user.subscribe, action.details.user._id]}}, loading: true, error: false}
                } else {
                    console.log("Inside else");
                    return{...state, authData: {...state.authData, user: {...state.authData.user, subscribe: [...state.authData.user.subscribe.filter((id)=>id!==action.details.user._id)]}}, loading: true, error: false}
                }
            case "SUBSCRIBE_USER_SUCCESS":
                console.log("REDUCER SUCCESS")
                return{...state, loading: false, error: null}
            case "SUBSCRIBE_USER_FAIL":
                console.log("subscribe failure")
                return{...state, loading: false, error: true}

            case "STAMP_DATUM":
                const stampDatumIdIndex = state.authData.user.stamps.findIndex((stamp)=>stamp.datumId===action.data.datumId); 
                if (stampDatumIdIndex === -1) {
                    const newStampObj = {datumId: action.data.datumId, triggers: []};
                    return{...state, authData: {...state.authData, user: {...state.authData.user, stamps: [...state.authData.user.stamps, newStampObj]}}}
                } else {
                    return{...state, authData: {...state.authData, user: {...state.authData.user, stamps: [...state.authData.user.stamps.filter((stamp)=>stamp.datumId!==action.data.datumId)]}}}
                }

            // Do I need a "VIEW_DATUM" here?

            case "USER_ITEM_INCREMENT":
                return{...state, authData: {...state.authData, user: {...state.authData.user, items: [...state.authData.user.items, {"itemId": action.data}]}}}
            case "USER_DATUM_INCREMENT":
                return{...state, authData: {...state.authData, user: {...state.authData.user, data: [...state.authData.user.data, {"datumId": action.data._id}]}}}
                

            // I guess I need start, success, and fail here too?
            case "CREATE_VALUE":
                return{...state, authData: {...state.authData, user: {...state.authData.user, compass: [...state.authData.user.compass, action.data]}}}


            case "ITEM_TRIGGER_START":
                return{...state, loading: true, error: false}
            case "ITEM_TRIGGER_SUCCESS":
                const stampIndex = state.authData.user.stamps.findIndex((stamp)=>stamp.datumId===action.data.datumId);
                // Need to distinguish between updating a trigger and setting it for the first time
                // Checking for item in triggers array
                const triggeredItemIndex = state.authData.user.stamps[stampIndex].triggers.findIndex((trig)=>trig.item===action.data.itemId);
                if (action.data.value === null) {
                    return{...state, authData: {...state.authData, user: {...state.authData.user, stamps: [...state.authData.user.stamps.map((stamp, i) => i === stampIndex ? {...stamp, triggers: [...state.authData.user.stamps[stampIndex].triggers.filter((trig)=>trig.item!==action.data.itemId)]} : stamp )]}}, loading: false, error: false}
                } else {
                    if (triggeredItemIndex === -1) {
                        // Set new trigger
                        const newTriggerObj = {item: action.data.itemId, value: action.data.value};
                        return{...state, authData: {...state.authData, user: {...state.authData.user, stamps: [...state.authData.user.stamps.map((stamp, i) => i === stampIndex ? {...stamp, triggers: [...state.authData.user.stamps[stampIndex].triggers, newTriggerObj]} : stamp )]}}, loading: false, error: false}
                    } else {
                        // Update existing trigger
                        return{
                            ...state, 
                            authData: {
                                ...state.authData, 
                                user: {
                                    ...state.authData.user,
                                    stamps: [
                                        ...state.authData.user.stamps.map((stamp, i) => i === stampIndex 
                                            ? {
                                                ...stamp, 
                                                triggers: [
                                                    ...state.authData.user.stamps[stampIndex].triggers.map((trig, i)=> i === triggeredItemIndex 
                                                        ? {...trig, value: action.data.value} 
                                                        : trig)    
                                                ]
                                            } 
                                            : stamp )]}}, 
                            loading: false, 
                            error: false
                        }
                    }
                }
            case "ITEM_TRIGGER_FAIL":
                return{...state, loading: false, error: true}


            case "LOG_OUT":
                localStorage.clear();
                return {authData: null, loading: false, error: null}
            
            
            default: 
                return state
        }
        
    };

export default authReducer;