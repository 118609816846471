import { LiaTimesSolid } from 'react-icons/lia';
import './AddRelationModal.css';

import React, { useEffect, useState } from 'react'
import { BsUpload } from 'react-icons/bs';
import { getRelations } from '../../../../api/ValuesRequest';

const AddRelationModal = ( { setOpenARelM, dataARelM, setDataARelM, dataARM } ) => {
  
    const buildObj = {
        ref1: "",
        relation: "",
        ref2: ""
    }
    
    const [relationBuild, setRelationBuild] = useState(buildObj);
    const [displayRelations, setDisplayRelations] = useState(null);

    const loadReferences1 = () => {
        var output = '<option value="" selected disabled hidden>Choose the first reference.</option>';;

        var categoriesPresent = 0;
        for (let ref = 0; ref < dataARM.length; ref++) {
            if (dataARM[ref].itemType === 4) {
                categoriesPresent++;
            }
        }
        for (let i = 0; i < dataARM.length; i++) {
            // Only allows Category type as ref1 if more than 1 Category type is present
            if (dataARM[i].itemType === 4) {
                if (categoriesPresent > 1) {
                    output += '<option value="' + dataARM[i]._id + '">' + dataARM[i].itemName + '</option>';        
                }
            } else {
                output += '<option value="' + dataARM[i]._id + '">' + dataARM[i].itemName + '</option>';
            }
        }
        
        const ref1El = document.getElementById("arelm_ref1_select");
        ref1El.innerHTML = output;
        ref1El.style.display = "block";
    }
    
    useEffect(()=> {
        document.getElementById("arelm_ref1_select") && loadReferences1();
        document.getElementById("arelm_relation_select") && loadRelations();
    }, [dataARM])

    useEffect(()=> {
        document.getElementById("arelm_relation_select") && loadRelations();
    }, [relationBuild.ref1])

    useEffect(()=> {
        if (relationBuild.ref1 !== "") {
            loadReferences2();
        }
    }, [relationBuild.ref1, relationBuild.relation]);

    const loadRelations = async() => {
        const res = await getRelations();
        const data = res.data;
        var output = '<option value="" selected disabled hidden>Choose the relation.</option>';
        // Determines if the remaining references are Categories (c), Non-Categories (n), or a mix (m)
        var refMakeup = {cat: 0, non: 0, key: ''};
        for (let ref = 0; ref < dataARM.length; ref++) {
            if (dataARM[ref]._id !== relationBuild.ref1) {
                if (dataARM[ref].itemType === 4) {
                    refMakeup.cat++;
                } else {refMakeup.non++}
            }
        }
        if (refMakeup.cat === 0 && refMakeup.non > 0) {refMakeup.key = "n"}
        if (refMakeup.cat > 0 && refMakeup.non === 0) {refMakeup.key = "c"}
        if (refMakeup.cat > 0 && refMakeup.non > 0) {refMakeup.key = "m"}
        for (let i = 0; i < data.length; i++) {
            // Adds all relations besides "belongs to (category)"
            if ((refMakeup.key === 'n' || refMakeup.key === 'm') && 
                data[i].relation !== "belongs to (category)") {
                output += '<option id="arelm_option_' + data[i].relation + '" value="' + data[i].relation + '">' + data[i].relation + '</option>';
            // Conditionally adds "belongs to (category)"" relation only if a Category Item type is present
            } else if ((refMakeup.key === 'c' || refMakeup.key === 'm') &&
                data[i].relation === "belongs to (category)") {
                output += '<option id="arelm_option_' + data[i].relation + '" value="' + data[i].relation + '">' + data[i].relation + '</option>';
            }
        }
        console.log(output)
        document.getElementById("arelm_relation_select").innerHTML = output;

        // Disallowing "replaces" if a relation has already been set
        if (dataARelM.length > 0) {
            document.getElementById("arelm_option_replaces").hidden = true;
            document.getElementById("arelm_option_replaces").disabled = true;
        }
    }
    
    const loadReferences2 = () => {
        var output = '<option value="" selected disabled hidden>Choose the second reference.</option>';;

        for (let i = 0; i < dataARM.length; i++) {
            // Excludes ref1 from the list
            if (dataARM[i]._id !== relationBuild.ref1) {
                // Adds only Category references if the relation is "belongs to (category)"
                if (relationBuild.relation === "belongs to (category)") {
                    if (dataARM[i].itemType === 4) {
                        output += '<option value="' + dataARM[i]._id + '">' + dataARM[i].itemName + '</option>';
                    }
                // Adds only non-Category references if relation is not "belongs to (category)"
                } else {
                    if (dataARM[i].itemType !== 4) {
                        output += '<option value="' + dataARM[i]._id + '">' + dataARM[i].itemName + '</option>';
                    }
                }
            }
        }
        const ref2El = document.getElementById("arelm_ref2_select");
        ref2El.innerHTML = output;
    }

    const handleRef1Change = (e) => {
        setRelationBuild({...relationBuild, ref1: e.target.value});
        document.getElementById("arelm_relation_select").style.display = "block";
        // console.log(relationBuild);
        
    }
    
    const handleRelationChange = (e) => {
        setRelationBuild({...relationBuild, relation: e.target.value});
        document.getElementById("arelm_ref2_select").style.display = "block";
        // console.log(relationBuild);
    }

    const handleRef2Change = (e) => {
        setRelationBuild({...relationBuild, ref2: e.target.value});
        document.getElementById("arelm_submit_button").style.display = "block";
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDataARelM(()=>{
            return [...dataARelM, relationBuild]
        })
        setOpenARelM(false);
    }
       
    return (
        
        dataARM.length > 1 
        
        ?

        <div className="AddRelationModal FlexColumn">
            <div className="arm-header FlexRow">
                <span className="arm-header-text">Add a Relation</span>
                <span className='arm-x' onClick={() => setOpenARelM(false)}>
                  <LiaTimesSolid/>
                </span>
            </div>
            <div className="Search arelm-search">
                <select id='arelm_ref1_select' className='arelm-dropdown' onChange={handleRef1Change}></select>
                <select id='arelm_relation_select' className='arelm-dropdown' onChange={handleRelationChange}></select>
                <select id='arelm_ref2_select' className='arelm-dropdown' onChange={handleRef2Change}></select>
                <button id='arelm_submit_button' className="button signupButton" style={{display: "none"}} onClick={handleSubmit}>
                    <BsUpload />
                </button> 
            </div>
            <div id="arm_content" className="arm-content">{displayRelations}</div>
        </div>

        : 
        
        <div className="AddRelationModal FlexColumn">
            <div className="arm-header FlexRow">
                <span className="arm-header-text">Add a Relation</span>
                <span className='arm-x' onClick={() => setOpenARelM(false)}>
                  <LiaTimesSolid/>
                </span>
            </div>
            <div id="arm_content" className="arelm-content">
                You must add at least 2 references before you can assign a relation.
            </div>
        </div>
        
  )
}

export default AddRelationModal