import { getAllValues } from "../api/ValuesRequest";

// Note!  The element id naming requires that only one instance be rendered at any given time
export const generateValueList = async (location) => {
    const res = await getAllValues();
    const data = res.data;
    console.log(data);
    var locationText;
    switch (location) {
        case "userFeedFilter":
            locationText = "Filter by value...";
            break;
        case "setCompass":
            locationText = "Choose a value to add to your Compass.";
            break;
    }
    var output = '<select id="gvl_dropdown" name="value" class="General-value-dropdown">' +
                    `<option id="gvl_default_option" value="" selected disabled hidden>${locationText}</option>`;
    for (let i = 0; i < data.length; i++) {
      var newString;
      switch (data[i].valueType) {
        case 0:
          newString = '<option disabled value="' + data[i]._id + '">Core Value: ' + data[i].value + '</option>';
          break;
        case 1:
          newString = '<option value="' + data[i].value + '">' + data[i].value + '</option>';
          break;
      }
        output += newString;
    }       
    output += '</select>'
    // document.getElementById("gvl_dropdown_container").innerHTML = output;
    return output;
}