import { useDispatch, useSelector } from 'react-redux'
import './ItemView.css'
import React, { useEffect, useState } from 'react'
import { AiOutlineBarChart, AiOutlineCheck } from 'react-icons/ai'
import { TbCompass, TbReplace } from 'react-icons/tb'
import { BiFilterAlt, BiPen } from 'react-icons/bi'
import { VscTypeHierarchy } from 'react-icons/vsc'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import { fetchModalUser } from '../../../../actions/ModalAction'
import { generateItemTypeIcon } from '../../../../tools/itemTypeConversion'
import { Tabs } from '@mantine/core'
import DatumList from '../Datum/DatumList'
import { ImLink } from 'react-icons/im'
import RelationalItem from './RelationalItem'
import Reference from '../../CreateDatum/AddReferenceModal/Reference/Reference'
import SuggestedItem from './SuggestedItem'
import { BsFillInfoCircleFill, BsSortDown } from 'react-icons/bs'
import { MdArrowForwardIos } from 'react-icons/md'


const ItemView = ( {data} ) => {

  const {user} = useSelector((state) => state.authReducer.authData)
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("info_data")
  const [riTools, setRiTools] = useState(null);
  const [relationalItems, setRelationalItems] = useState(data.relationalItems.toSorted((a, b)=>b.relatedData.length - a.relatedData.length));
  const [relationFilter, setRelationFilter] = useState(null);

  useEffect(()=>{
    setActiveTab("info_data");
  }, [data])

  const createdDate = () => {
      const date = new Date(data.createdAt);
      return (date.toDateString());
  }

  const countReferences = () => {
    var count = 0;
    // I think I need to come back here and correct - it was throwing for group type 
    // for not having a stampDetails array
    if (user.userType === 0) {
      for (let d = 0; d < data.infoData.length; d++) {
        count += data.infoData[d].stampDetails.length;
      }
      for (let d = 0; d < data.relationalData.length; d++) {
        count += data.relationalData[d].stampDetails.length;
      }
    }
    return count;
  }

  const adoptionValue = (() => {
    if (data.adoptions.findIndex(i=>i===user._id) > -1) {
      return data.adoptions.findIndex(i=>i===user._id) + 1;
    } else {
      return null;
    }
  })()
  
  const parentItemObj = {
    parent_itemId: data._id,
    parent_itemName: data.itemName
  }

  const loadUser = () => {
    const logDetails = {
      userId: user._id,
      username: user.username,
      ...parentItemObj
    }
    dispatch(fetchModalUser(data.userId, logDetails))
  }

  const clearRiFilters = () => {
    setRelationFilter(null);
    document.getElementById("iv_relation_dropdown").selectedIndex = 0;
  }

  // Does this need to be separated by tab, or can I make a general function?
  const generateRiTools = (tool) => {
    switch (tool) {
      case "info":
        console.log("Firing info case");
        setRiTools(
          <>
            <div className='iv-th-text'>
              Every Item that is "related" to this Item is displayed here.  For more information about how two Items are related, click on the {<AiOutlineBarChart />} icon next to the Item to view all of the Data that describe their relationship.<br/><br/>
              Use the {<BiFilterAlt />} tool to Filter the Items by the type of relationship.
            </div>
            <div onClick={()=>generateRiTools("close")} className='iv-th-arrow-container'>
              <MdArrowForwardIos className='General-exp-arrow' />

            </div>
          </>
        )
        if (document.getElementById("iv_ri_tools").classList.contains("General-hidden")) {
          handleExpandTools("ri");
        } 
        break;
      case "filter":
        console.log("firing filter case");
        setRiTools(
          <>
            <div className='iv-th-text'>
              Sort and Filter Options:
            </div>
            <div className='iv-th-filter-container'>
              <select id="iv_relation_dropdown" class="iv-relation-dropdown" onChange={(e)=>setRelationFilter(e.target.value)}>
                <option value="" selected disabled hidden>Filter by Relation:</option>
                {/* {Object.keys(data.relationCounts).map(r => {
                  console.log(r, data.relationCounts[r]);
                  if (data.relationCounts[r] > 0) {
                    return <option value={r}>{r}</option>
                  }
                })}    */}
                {data.relationCounts.map(r => {
                  if (r.count > 0) {
                    return <option value={r.text}>{r.text}</option>
                  }
                })}   
              </select>
              <button onClick={()=>clearRiFilters()}>Clear</button>
            </div>
            <div onClick={()=>generateRiTools("close")} className='iv-th-arrow-container'>
              <MdArrowForwardIos className='General-exp-arrow' />

            </div>
          </>
        );
        if (document.getElementById("iv_ri_tools").classList.contains("General-hidden")) {
          handleExpandTools("ri");
        } 
        break;
      case "close":
        console.log("firing close");
        setRiTools(null);
        handleExpandTools("ri");
    }

  }

  const handleExpandTools = (t) => {
    document.getElementById("iv_" + t + "_tools").classList.toggle("General-hidden");
  }

  useEffect(()=> {
    // Filter 
    if (relationFilter) {
      
      // Filter items by relationFilter
      var itemsFiltered = data.relationalItems.filter((item) => item.relations.includes(relationFilter));
      var dataFiltered = itemsFiltered.map((item)=> {
        return {...item, relatedData: item.relatedData.filter((datum)=> {
          var allChecks = false;
          for (let r = 0; r < datum.relations.length; r++) {
            // If 2 items match AND relationFilter matches, pass through
            let item1 = datum.relations[r].ref1;
            let item2 = datum.relations[r].ref2;
            let relation = datum.relations[r].relation;
            let item1Match, item2Match;
            console.log(item1, item2, relation, relationFilter);
            console.log(item.itemId, data._id);
            if (item1 === item.itemId || item1 === data._id) {item1Match = true;}
            if (item2 === item.itemId || item2 === data._id) {item2Match = true;}
            console.log(item1Match, item2Match);
            if (item1Match && item2Match && relation === relationFilter) {
              allChecks = true;
            }
          }
          return (allChecks)

        })}
      })
      
      // Sort
      const sorted = dataFiltered.sort((a, b)=>a.triggers - b.triggers);
      setRelationalItems(sorted)
    } else {
      setRelationalItems(data.relationalItems.toSorted((a, b)=>b.relatedData.length - a.relatedData.length));
    }
  }, [relationFilter]);

  return (
        <div 
            className="iv-item-container"
            // Eventually the class will depend on if item is triggered.
        >
            <div className="datumBody">
                <div className="iv-top-bar">
                    <span className='iv-name FlexColumn'>
                        {data.itemName}
                        <img src={process.env.REACT_APP_PUBLIC_FOLDER + data.image} class="iv-item-image"></img>
                    </span>
                </div>
                <div className="iv-stats FlexRow">
                    {user.userType !== 1 
                      ? <div className={data.totalTriggers > 0 ? "FlexRow iv-status-conflict" : "FlexRow iv-status-clear"}>
                          {data.totalTriggers > 0
                            ? 
                              <>
                                <TbCompass size="1rem"/>
                                <span className="conflictCount">{data.totalTriggers}</span>
                              </>
                            : <AiOutlineCheck size="1rem"/>
                          }
                        </div>
                      : ''
                    }
                    <div className="iv-specific-stats FlexRow">
                      <AiOutlineBarChart size="1rem" />
                      <span className="referenced">{data.infoData.length + data.relationalData.length}</span>
                      {user.userType === 1 && adoptionValue 
                        ? 
                          <>
                            <ImLink/>
                            <span className="referenced">{adoptionValue}</span>
                          </>
                        : ""
                      }
                    </div>
                    <div className="iv-overall-stats FlexRow">
                      <VscTypeHierarchy size="1rem" />
                      <span className="referenced">{data.links.length}</span>
                      <ImLink/>
                      <span className="referenced">{data.adoptions.length}</span>
                    </div>
                    {generateItemTypeIcon(data.itemType)}
                </div>
            </div>

            <hr/>


            <Tabs radius="md" defaultValue="info_data" value={activeTab} onTabChange={setActiveTab}>
              <Tabs.List grow>
                <Tabs.Tab value="info_data" icon={
                  <span className='iv-tabWithNotification'><AiOutlineBarChart size="1rem" />
                    {data.itemTriggers.infoTriggers > 0 && <span className='iv-trigger-count'>{data.itemTriggers.infoTriggers}</span>}
                  </span>}
                ></Tabs.Tab>
                <Tabs.Tab value="relational_data" icon={
                  <span className='iv-tabWithNotification'><HiArrowsRightLeft size="1rem" /><AiOutlineBarChart size="1rem" />
                    {data.itemTriggers.relationalTriggers > 0 && <span className='iv-trigger-count'>{data.itemTriggers.relationalTriggers}</span>}
                  </span>}
                ></Tabs.Tab>
                <Tabs.Tab value="relational_items" icon={
                  <span className='iv-tabWithNotification'><HiArrowsRightLeft size="1rem" /><VscTypeHierarchy size="1rem" />
                    {data.relationalTriggers > 0 && <span className='iv-trigger-count'>{data.relationalTriggers}</span>}
                  </span>}
                ></Tabs.Tab>
                <Tabs.Tab value="suggestions" icon={
                  <span className='iv-tabWithNotification'><TbReplace/>
                    {data.suggestions.length > 0 && <span className='iv-suggestions-count'>{data.suggestions.length}</span>}
                  </span>}
                ></Tabs.Tab>
                <Tabs.Tab value="author" icon={<BiPen size="1rem" />}></Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="info_data" pt="xs">
                <div className="iv-data-container">
                  {data.infoData.length > 0 ? data.infoData.map((datum, _id) => {
                    return <DatumList data={datum} key={_id} />
                  })
                  : "This item does not have any info data."
                  }
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="relational_data" pt="xs">
                <div className="iv-data-container">
                  {data.relationalData.length > 0 ? data.relationalData.map((datum, _id) => {
                    return <DatumList data={datum} key={_id} />
                  })
                  : "This item does not have any relational data."
                  }
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="relational_items" pt="xs">
                <div className='iv-tabHeader'>
                  <div className='iv-th-top'>
                    <span className='iv-th-text'>
                      Relational Items:
                    </span>
                    <span className='iv-th-rightIcons'>
                      <span onClick={()=>generateRiTools("info")}>
                        <BsFillInfoCircleFill />
                      </span>
                      <span onClick={()=>generateRiTools("filter")}>
                        <BiFilterAlt />
                      </span>
                    </span>
                  </div>
                  <div id="iv_ri_tools" className='iv-th-tools General-hidden'>
                    {riTools}
                  </div>
                </div>
                <div className="iv-data-container">
                  {relationalItems.length > 0 ? relationalItems.map((item, id) => {
                    return <RelationalItem item={item} key={item.itemId} parentItemObj={parentItemObj} />
                  })
                  : relationFilter 
                    ? "An error occurred.  This should not happen"
                    : "This item does not have any relational items."
                  }
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="suggestions" pt="xs">
                <div className="iv-data-container">
                  {data.suggestions.length > 0 ? data.suggestions.map((item, id) => {
                    return <SuggestedItem item={item} key={item.itemId} parentItemObj={parentItemObj} />
                  })
                  : "This item does not have any suggestions."
                  }
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="author" pt="xs">
                <div className="bottom-bar FlexRow">
                  <div className="datumDetails">
                    <div className="generalBar authorBar" onClick={loadUser}>
                        <BiPen/>
                        
                        <img src={process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage} alt="" className='authorBarImg' />
                        <span className="datumAuthor">
                            @{data.authorName}
                        </span>
                        <span className="timestamp">{createdDate()}</span>
                    </div>
                  </div>
                </div>
              </Tabs.Panel>
            </Tabs>
        
        </div>




  )
}

export default ItemView