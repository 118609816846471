import React, { useEffect } from 'react'
import './InfoCard.css'
import { useDisclosure } from '@mantine/hooks';
import { Modal, useMantineTheme } from '@mantine/core';
import { useSelector } from 'react-redux';
import { generateUserTypeIcon } from '../../../tools/userTypeConversion';
import { BiEditAlt } from 'react-icons/bi';
import UserView from '../../User/UserView'
import { RiProfileLine } from 'react-icons/ri';
import { MdArrowForwardIos } from 'react-icons/md';

const InfoCard = ( {profileContent, setProfileContent} ) => {

  const {user} = useSelector((state) => state.authReducer.authData);
  
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();

  const generateUserType = () => {
    switch (user.userType) {
      case 0: 
        return "General";
      case 1: 
        return "Author";
      case 2: 
        return "Group";
    }
  }

  const handleInfoCardCollapse = () => {
    document.getElementById("ic_wrapper").classList.toggle("Profile-card-collapsed");
    document.getElementById("ic_expand_icon").classList.toggle("profile-contracted");
  }

  useEffect(()=>{
    if (!document.getElementById("ic_wrapper").classList.contains("Profile-card-collapsed")) {
        handleInfoCardCollapse();
    }
}, profileContent)

  const modalSize = () => {
    const width = window.innerWidth;
    if (width < 650) {
      return width * 0.8;
    } else {
      return 575;
    }
  }

  return (
    <div className="AppGeneralCard">
        <div id="ic_wrapper" className="InfoCard-wrapper">
            <div className="generalBar">
                <RiProfileLine/>
                <span className='pointText'>
                    My Profile
                </span>
                <span id="ic_expand_icon" className="profile-expand-icon"><MdArrowForwardIos onClick={handleInfoCardCollapse} /></span>
                <span className="ic-edit-icon"><BiEditAlt onClick={()=>setProfileContent("editProfile")} /></span>
            </div>
            
            <div className="ic-line FlexRow">
                <span className="ic-inline-tag">Name</span>
                <span className="ic-value">{user.displayname}</span>
            </div>
            <div className="ic-line FlexRow">
                <span className="ic-inline-tag">Username</span>
                <span className="ic-value">@{user.username}</span>
            </div>
            <div className="ic-line FlexRow">
                <span className="ic-inline-tag">User Type</span>
                <span className="ic-value FlexRow">{generateUserType()}{generateUserTypeIcon(user.userType)}</span>
            </div>
            <div className="ic-line">
                <span className="ic-tag">Tagline</span>
            </div>
            <div className="ic-line">
                <span className="ic-dropped-value">{user.tagline}</span>
            </div>
            <div className="ic-line">
                <span className="ic-tag">Statement</span>
            </div>
            <div className="ic-line">
                <span className="ic-dropped-value">{user.statement}</span>
            </div>
            
            <hr/>

            <div className="AppGeneralEditLine" onClick={open}>
            View Profile        
            </div>
  
        </div>

        <Modal
        opened={opened}
        onClose={close}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        size={modalSize()}
        withCloseButton={false}
      >
        <UserView user={user} />            
      </Modal>
    </div>
  )
}

export default InfoCard