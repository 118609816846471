import React from 'react'
import './UserView.css'
import { generateUserTypeIcon } from '../../tools/userTypeConversion';
import { VscTypeHierarchy } from 'react-icons/vsc'
import { AiOutlineBarChart, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { GiStamper } from 'react-icons/gi'
import { BsBookmarkCheck, BsEye, BsInfo } from 'react-icons/bs'
import { RiUserFollowFill, RiUserFollowLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import { TbCompass } from 'react-icons/tb'
import { followUser, subscribeUser } from '../../actions/UserAction';
import { Tabs } from '@mantine/core';
import Value from '../ProfileLeft/Compass/Value/Value';
import UserStats from './UserStats';
import { getDataFeed } from '../../actions/DataFeedAction';

const UserView = ({user}) => {

  const dispatch = useDispatch();

  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const {user: sessionUser, loading: authDataLoading} = useSelector((state) => state.authReducer.authData);
  
  const actionType = () => {
    switch (sessionUser.userType) {
      case 0:
        if (user.userType === 2) {
          return {
            db: "follow",
            action: "Follow",
            reverse: "Unfollow"
          };
        } 
        break;
      case 1:
        break;
      case 2:
        if (user.userType === 1) {
          return {
            db: "subscribe",
            action: "Subscribe",
            reverse: "Unsubscribe"
          };
        }
        break;
    }
  }

  const generateButton = () => {
    const getActions = actionType();
    if (getActions != null) {
      const dblist = getActions["db"];
      if (!sessionUser[dblist].includes(user._id)) {
        return (
          <button 
            className='button' 
            onClick={()=>{handleAction(dblist)}}
          >
            {getActions["action"]}
          </button>
        )
      } else {
        return (
          <button 
            className='grayButton'
            onClick={()=>{handleAction(dblist)}}
          >
            {getActions["reverse"]}
          </button>
        )
      }
    } 
  }

  const handleAction = (db) => {
    const userCompass = user.compass.map((v)=>{
      return({
        value: v.value,
        group: user._id
    })})
    const userDetails = {
      user: user,
      compass: userCompass
    }
    // !!!! Need to refresh UserView stats and FollowCard after action
    // Why does the user array need to be set equal to the filtered array, but push doesn't?
    if (db === "follow") {

      const followLogic = () => {
        dispatch(followUser(userDetails, sessionUser))
        
        // Don't I want to be doing this action in the reducer?
        // Isn't this duplicate of what's happening in authReducer @ FOLLOW_USER?
        // Commenting to see if it removes functionality
        // if (!user.follow.includes(sessionUser._id)) {
        //   user.follow.push(sessionUser._id);
        // } else {
        //   user.follow = user.follow.filter((id)=>id!==sessionUser._id);
        // }
        // After commenting, functionality was still normal - must have been redundant.  Keeping just in case.
      }

      // Demo section - remove the if/else logic later
      if (sessionUser.isAdmin) {
        // user is Admin - go ahead 
        followLogic();
      } else {
        if (user.isAdmin) {
          // error - regular user cannot follow admin
        } else {
          // regular user following regular user - go ahead
          followLogic();
        }
      }
    } else {

      const subscribeLogic = () => {
        dispatch(subscribeUser(userDetails, sessionUser));
        // same as in follow above
        // similarly commented out as above
        // if (!user.subscribe.includes(sessionUser._id)) {
        //   user.subscribe.push(sessionUser._id);
        // } else {
        //   user.subscribe = user.subscribe.filter((id)=>id!==sessionUser._id);
        // }
      }

      // Demo section - remove the if/else logic later
      if (sessionUser.isAdmin) {
        // user is Admin - go ahead 
        subscribeLogic();
      } else {
        if (user.isAdmin) {
          // error - regular user cannot follow admin
        } else {
          // regular user following regular user - go ahead
          subscribeLogic();
        }
      }
    }
  }


  return (
    <div className="UserView AppGeneralCard FlexColumn">
        <div className="ProfileImages">
          <img src={serverPublic + user.userCover} alt="" />
          <img src={serverPublic + user.userImage} alt="" />
        </div>
        <div className='FlexColumn uv-profile-container'>
          <div className='FlexColumn uv-profile-info'>
            <div className="uv-profile-header">
              <div className="uv-profile-name">
                {/* <span>(Points)</span> */}
                <span>@{user.username}</span>
                <span>{user.displayname}</span>
              </div>
              <div className='uv-type-icon-container'>
                {generateButton()}
                {generateUserTypeIcon(user.userType)}
              </div>
            </div>

            <UserStats user={user}/>
              
          </div>



          <Tabs radius="md" defaultValue="profile" className='GeneralTabs'>
            <Tabs.List grow>
              <Tabs.Tab value="profile" icon={<BsInfo />}></Tabs.Tab>
              {user.userType !== 1
                ? <Tabs.Tab value="compass" icon={<TbCompass />}></Tabs.Tab> : ""
              }
              {user.userType !== 0
                ? <Tabs.Tab value="data" icon={<AiOutlineBarChart />}></Tabs.Tab> : ""
              }
              {user.userType === 1
                ? <Tabs.Tab value="items" icon={<VscTypeHierarchy/>}></Tabs.Tab> : ""
              }
              
            </Tabs.List>

            <Tabs.Panel value="profile" pt="xs">
              <div className='FlexColumn uv-profile-container'>
                <span>{user.tagline}</span>
                <span>{user.statement}</span>
              </div>
            </Tabs.Panel>

            {user.userType !== 1 ?
              <Tabs.Panel value="compass" pt="xs">
                <div className='uv-compass-container'>
                    {user.compass.length > 0
                      ? user.compass.map((value, id)=>{
                          return <Value data={value} location="compassTab" key={id} />
                      })
                      : "This user has not set their Compass yet."
                    }
                </div>
              </Tabs.Panel>
            : ""}

            {user.userType !== 0 ?
              <Tabs.Panel value="data" pt="xs">
                <div className='FlexRow uv-data-container'>
                    Data
                </div>
              </Tabs.Panel>
            : ""}

            {user.userType === 1 ?
              <Tabs.Panel value="items" pt="xs">
                <div className='FlexRow uv-items-container'>
                    Data
                </div>
              </Tabs.Panel>
            : ""}


          </Tabs>


          
        </div>
    </div>
  )
}

export default UserView