import * as LogsApi from '../api/LogsRequest'

export const fetchLogs = () => async(dispatch) => {
    dispatch({type: "FETCH_LOGS_START"});
    try {
        const {data} = await LogsApi.fetchLogs();
        dispatch({type: "FETCH_LOGS_SUCCESS", data: data});
    } catch (error) {
        console.log(error);
        dispatch({type: "FETCH_LOGS_FAIL"});
    }    
}