import React, { useEffect, useState } from 'react'
import './Auth.css'
import Logo from '../../img/appiconcrop3.png'
import { useDispatch, useSelector } from 'react-redux'
import { clearAuthError, logIn, signUp, updatePass } from '../../actions/AuthAction'
import { Link } from 'react-router-dom'


const Auth = () => {

    const dispatch = useDispatch();
    const {loading, error, message} = useSelector((state)=>state.authReducer);
    const [rightComponent, setRightComponent] = useState("logIn");

    const blankDataObj = {
        displayname: "", 
        username: "", 
        password: "", 
        confirmPassword: "", 
        userType: "0"
    }

    useEffect(()=>{
        
        switch (rightComponent) {
            case "signUp":
                if (error !== null) {
                    const signupValEl = document.getElementById("auth_signup_validation");
                    signupValEl.classList.remove("General-hidden")
                    switch (error) {
                        case 400:
                            signupValEl.innerText = "Username is already taken.";
                            break;
                        case 500:
                            signupValEl.innerText = "A server error has occurred.  Please try again later.";
                            break;
                        default:
                            signupValEl.innerText = error;
                    }
                } else {
                    // hide signupvalel?
                }
                break;
            case "logIn":
                if (error !== null) {
                    const loginValEl = document.getElementById("auth_login_validation")
                    loginValEl.classList.remove("General-hidden")
                    switch (error) {
                        case 400:
                        case 404:
                            loginValEl.innerText = "Incorrect username or password.";
                            break;
                        case 500:
                            loginValEl.innerText = "A server error has occurred.  Please try again later.";
                            break;
                        default:
                            loginValEl.innerText = error;
                    }
                }
                break;
            case "forgot":
                const forgotValEl = document.getElementById("auth_forgot_validation")
                if (error !== null) {
                    forgotValEl.classList.remove("General-hidden")
                    switch (error) {
                        case 400:
                        case 404:
                            forgotValEl.innerText = "Password reset is not allowed for this Username.";
                            break;
                        case 500:
                            forgotValEl.innerText = "A server error has occurred.  Please try again later.";
                            break;
                        default:
                            forgotValEl.innerText = error;
                    }
                }
                if (message !== null) {
                    forgotValEl.classList.remove("General-hidden", "General-validationError");
                    forgotValEl.classList.add("General-validationSuccess");
                    console.log("Inside message");
                    console.log(message);
                    forgotValEl.innerText = message;
                }
                break;
        }
        dispatch(clearAuthError(data));
    }, [error, message, rightComponent])

    const [data, setData] = useState(blankDataObj);

    const handleChange = (e)=> {
        setData({...data, [e.target.name]: e.target.value});
    }

    const handleSubmitSignup = async (e) => {
        e.preventDefault();
        const signupValEl = document.getElementById("auth_signup_validation");
        if (data.password !== data.confirmPassword) {
            signupValEl.innerText = "Your passwords do not match.";
            signupValEl.classList.remove("General-hidden");
        } else if (
            document.getElementById("auth_displayname").value === '' || 
            document.getElementById("auth_username").value === '' ||
            document.getElementById("auth_password").value === '' ||
            document.getElementById("auth_confirm").value === '') {
            signupValEl.innerText = "Please fill in all fields.";
            signupValEl.classList.remove("General-hidden");
        } else if (document.getElementById("auth_password").value.length < 8) {
            signupValEl.innerText = "Your password must be at least 8 characters long.";
            signupValEl.classList.remove("General-hidden");
        } else {
            signupValEl.classList.add("General-hidden");
            dispatch(signUp(data)) 
        }
    }

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        const loginValEl = document.getElementById("auth_login_validation")
        if  (document.getElementById("login_username").value === '' ||
            document.getElementById("login_password").value === '') {
                console.log("Login not all fields")
            loginValEl.innerText = "Please fill in all fields.";
            loginValEl.classList.remove("General-hidden");
        } else {
            loginValEl.classList.add("General-hidden");
            dispatch(logIn(data))
        }
    }

    const handleSubmitForgot = async (e) => {
        e.preventDefault();
        const signupValEl = document.getElementById("auth_forgot_validation");
        if (data.password !== data.confirmPassword) {
            signupValEl.innerText = "Your passwords do not match.";
            signupValEl.classList.remove("General-hidden");
        } else if (
            document.getElementById("forgot_username").value === '' ||
            document.getElementById("forgot_password").value === '' ||
            document.getElementById("forgot_confirm").value === '') {
            signupValEl.innerText = "Please fill in all fields.";
            signupValEl.classList.remove("General-hidden");
        } else if (document.getElementById("forgot_password").value.length < 8) {
            signupValEl.innerText = "Your password must be at least 8 characters long.";
            signupValEl.classList.remove("General-hidden");
        } else {
            signupValEl.classList.add("General-hidden");
            dispatch(updatePass(data));
            // setRightComponent("logIn");
        }
    }

    const renderRightComponent = () => {
        switch (rightComponent) {
            case "signUp":
                return <SignUp
                setRightComponent={setRightComponent}
                handleChange = {handleChange}
                handleSubmitSignup = {handleSubmitSignup}
                loading = {loading}
                />
            case "logIn":
                return <LogIn
                setRightComponent={setRightComponent}
                handleChange = {handleChange}
                loading = {loading}
                handleSubmitLogin = {handleSubmitLogin}
                />
            case "forgot":
                return <Forgot
                setRightComponent={setRightComponent}
                handleChange = {handleChange}
                loading = {loading}
                handleSubmitForgot = {handleSubmitForgot}
                />
        }
    }

  return (
    <div className="Auth">
        <div className="auth-left">
            <Link style={{textDecoration: "none", color: "inherit"}} to={'/landing'}><img src={Logo} alt="" className='auth-logo' /></Link>
        </div>
        {renderRightComponent()}
    </div>
  )
}

function LogIn({ setRightComponent, handleChange, loading, handleSubmitLogin }) {
    return (
        <div className="auth-right">
            <form action="" className="input-form" onSubmit={handleSubmitLogin}>
                <h3>Log In</h3>
                
                <input 
                    type="text"
                    placeholder='Username'
                    className='signupInput'
                    name='username'
                    id="login_username"
                    onChange = {handleChange}
                />
                <input 
                    type="password"
                    placeholder='Password'
                    className='signupInput'
                    name='password'
                    id="login_password"
                    onChange = {handleChange}
                />
                <div id="auth_login_validation" className='validationError General-hidden'></div>
                <div className="loginSignup" onClick={()=>setRightComponent("signUp")}>
                    Don't have an account?  Sign up.
                </div>
                <div className="loginSignup" onClick={()=>setRightComponent("forgot")}>
                    Forgot your password?
                </div>
                <button className="button signupButton" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Log In"}
                </button>
                
                    
            </form>
        </div>
    )
}

function SignUp({ setRightComponent, handleChange, handleSubmitSignup, loading }) {

    return (
        <div className="auth-right">
            <form action="" className="input-form" onSubmit={handleSubmitSignup}>
                <h3>Sign Up</h3>
                
                <label 
                    name="userType"
                    className='signupDropdownLabel'
                >
                    <span>
                        User Type:
                    </span>
                    <select 
                        name="userType" 
                        className='signupDropdown'
                        onChange = {handleChange}
                    >
                        <option value="0">General User</option>
                        <option value="1">Author</option>
                        <option value="2">Group</option>
                    </select>
                </label>
                <input 
                    type="text"
                    placeholder='Name'
                    className='signupInput'
                    name='displayname'
                    id="auth_displayname"
                    onChange = {handleChange}
                />
                <input 
                    type="text"
                    placeholder='Username'
                    className='signupInput'
                    name='username'
                    id="auth_username"
                    onChange = {handleChange}
                />
                <input 
                    type="password"
                    placeholder='Password'
                    className='signupInput'
                    name='password'
                    id="auth_password"
                    onChange = {handleChange}
                />
                <input 
                    type="password"
                    placeholder='Confirm Password'
                    className='signupInput'
                    name='confirmPassword'
                    id="auth_confirm"
                    onChange = {handleChange}
                />

                <div id="auth_signup_validation" className='validationError General-hidden'></div>
                <div className="loginSignup" onClick={()=>setRightComponent("logIn")}>
                    Already have an account?  Log in.
                </div>
                <button className="button signupButton" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Sign Up"} 
                </button>
                    
            </form>
        </div>
    )
}

function Forgot({ setRightComponent, handleChange, loading, handleSubmitForgot }) {
    return (
        <div className="auth-right">
            <form action="" className="input-form" onSubmit={handleSubmitForgot}>
                <h3>Forgot Password</h3>
                <span className='auth-instruction'>If you forgot your password, please contact North Star admin to have it reset, then proceed with this Update form.</span>
                <input 
                    type="text"
                    placeholder='Username'
                    className='signupInput'
                    name='username'
                    id="forgot_username"
                    onChange = {handleChange}
                />
                <input 
                    type="password"
                    placeholder='New Password'
                    className='signupInput'
                    name='password'
                    id="forgot_password"
                    onChange = {handleChange}
                />
                <input 
                    type="password"
                    placeholder='Confirm Password'
                    className='signupInput'
                    name='confirmPassword'
                    id="forgot_confirm"
                    onChange = {handleChange}
                />
                <div id="auth_forgot_validation" className='General-validationError General-hidden'></div>
                <div className="loginSignup" onClick={()=>setRightComponent("logIn")}>
                    Back to Login.
                </div>
                <button className="button signupButton" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Update"}
                </button>
                
                    
            </form>
        </div>
    )
}


export default Auth