import { useDispatch, useSelector } from 'react-redux'
import { fetchLogs } from '../../actions/LogsAction'
import './Logs.css'

import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'

const Logs = () => {
    
    const {logs} = useSelector((state)=>state.logsReducer);
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch(fetchLogs());
      },[])

    const createdDate = (timestamp) => {
        const date = new Date(timestamp);
        const dateTime = date.toDateString() + " " + date.toLocaleTimeString();
        return (dateTime);
    }

  return (
    <div className="HeaderGrid">
        <div className='home-header-container'>
            <Header location="home"/>
        </div>
        <div className="Logs">
            {logs && logs.map((entry)=>{

                const entryStyle = () => {
                    switch (entry.status) {
                        case 0:
                            return "";
                        case 1:
                            return " logs-alert";
                        case 2: 
                            return " logs-error";
                        default:
                            return "";
                    }
                }

                return (
                <div className={"logs-entry " + entryStyle()}>
                    <div className='logs-date'>{createdDate(entry.createdAt)}</div>
                    <div className='logs-activity'>{entry.activity}</div>
                    <div>{entry.username}</div>
                    <div>{entry.target}</div>
                    <div className='logs-dataObj'>{
                        Object.keys(entry.data).map(e => {
                            // For typical keypair entries
                            console.log("Typeof is ", typeof entry.data[e]);
                            
                            
                            if (typeof entry.data[e] === "string") {
                                console.log("Inside typeof is not object")
                                return <div className='logs-dataObjLine'><span className='logs-dataObjKey'>{e}</span><span className='logs-dataObjValue'>{entry.data[e]}</span></div>
                            }
                            // For object array values (updating transferrence)
                            // else if (typeof entry.data[e] === "object" && entry.data[e].length) {
                            //     console.log("Inside typeof is Object and has array length")
                            //     return <div className='logs-dataObjLine'><span className='logs-dataObjKey'>{e}</span><span className='logs-dataObjValue'>
                            //         {entry.data[e].map(a => {
                            //             console.log(a);
                            //             Object.keys(a).map(tk => {
                            //                 return <div className='logs-dataObjLine'><span className='logs-dataObjKey'>{tk}</span><span className='logs-dataObjValue'>{a[tk]}</span></div>
                            //             })
                            //         })}
                            //     </span></div>
                            // }

                            else {
                                return "See DB entry for data"
                            }

                        })}
                    </div>
                    <div className='logs-dataObj'>{
                        entry.response && Object.keys(entry.response).map(e => {
                            return <div className='logs-dataObjLine'><span className='logs-respObjKey'>{e}</span><span className='logs-dataObjKey'>{entry.response[e]}</span></div>
                        })}
                    </div>
                </div>
                )
            })}
        </div>
    </div>
    
  )
}

export default Logs