import React from 'react'
import './Value.css'
import {BiEditAlt} from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { MdArrowForwardIos } from 'react-icons/md';
import { getAllValues } from '../../../../api/ValuesRequest';

const Value = ({ data, location, id }) => {

  var userImage = '';
  const actionFeed = useSelector((store)=>store.actionFeedReducer.actionFeed.follow);
  const {user} = useSelector((state)=>state.authReducer.authData);

  const generateContent = () => {
    switch (location) {
      case "myCompass":
        if (data.group) {
          const group = actionFeed.filter((user)=>user._id==data.group);
          userImage = group[0].userImage;
        }
        
        return (
          <div className="value-text">
            <span>{data.value}</span>
            {data.group && <span><img src={process.env.REACT_APP_PUBLIC_FOLDER + userImage} /></span>}
          </div>
        )
      
      case "setCompass":
        
        return (
          <div className="value-set-compass">
            <div className='value-text FlexRow'>
              <span><b>{data.value}</b></span>
              <span className='value-edit-icon'><BiEditAlt/></span>
            </div>
            {data.expound ? <span className='value-expound'>{data.expound}</span> : ""}
          </div>
        )
          
      case "compassTab":
        if (data.groupImage) {
          userImage = data.groupImage;
        } else if (data.group) {
          const group = actionFeed.filter((user)=>user._id==data.group);
          userImage = group[0].userImage; 
        }
        
        return (
          <>
            <div className="value-text">
              <span><b>{data.value}</b></span>
              {data.group && <span><img src={process.env.REACT_APP_PUBLIC_FOLDER + userImage} /></span>}
              
            </div>
            {data.expound &&
              <div className="value-expound" id={"value_expound_" + id}>
                <span>{data.expound}</span>
              </div>
            }
          </>
        )        

      case "userDetail":
        if (data.groupImage) {
          userImage = data.groupImage;
        } else if (data.group) {
          const group = actionFeed.filter((user)=>user._id==data.group);
          userImage = group[0].userImage; 
        }
        
        return (
          <>
            <div className="value-text" onClick={()=>handleExpandValue(id)}>
              <span><b>{data.value}</b></span>
              {data.group && <span><img src={process.env.REACT_APP_PUBLIC_FOLDER + userImage} /></span>}
              {data.expound && <MdArrowForwardIos id={"value_expand_" + id} className='General-exp-arrow General-exp-arrow-con' />}
            </div>
            {data.expound &&
              <div className="value-expound General-hidden" id={"value_expound_" + id}>
                <span>{data.expound}</span>
              </div>
            }
          </>
        )        
    }
  }

  const handleExpandValue = (i) => {
    document.getElementById("value_expound_" + i).classList.toggle("General-hidden");
    document.getElementById("value_expand_" + i).classList.toggle("General-exp-arrow-con");
  }

  return (
    <div className='Value'>  
      {/* wait for server response here */}
      {generateContent()}
    </div>
  );
}

export default Value