import axios from "axios"

const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

// API.interceptors.request.use((req)=> {
//     if (localStorage.getItem('profile')) {
//         req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile').token)}`
//     }
//     return req;
// })

export const getUser = (userId, logDetails) => API.get(`/user/${userId}/${logDetails}`)
// export const getUsers = (id, action) => API.get(`/user/${action}/${id}`)

export const followUser = (id, data) => API.put(`/user/${id}/follow`, data)
export const subscribeUser = (id, data) => API.put(`/user/${id}/subscribe`, data)
export const updateUser = (id, formData) => API.put(`/user/${id}/update`, formData)
export const updateTransferrence = (id, data) => API.put(`/user/${id}/transferrence`, data)