import React, { useEffect, useState } from 'react'
import ProfileCard from './ProfileCard/ProfileCard'
import './ProfileSide.css'
import FollowCard from './FollowCard/FollowCard'
import FollowBar from './FollowCard/FollowBar/FollowBar'
import { useSelector } from 'react-redux'
import ItemSearch from '../Main/ItemSearch/ItemSearch'

const ProfileSide = () => {
  
  const {user} = useSelector((state)=>state.authReducer.authData);
  const userType = user.userType;
  
  const typeSelector = (selected) => {
    setActiveType(selected)
  }
  
  const startingFollowType = () => {
    switch (userType) {
      case 0: 
        return "f_groups";
      case 1: 
        return "f_groups";
      case 2:
        return "f_authors";
      }
    }

  const [activeType, setActiveType] = useState(startingFollowType());
  const [followDetails, setFollowDetails] = useState({});
    
  useEffect(() => {
    const setRelativeUserAction = () => {
      // console.log("Inside setRelativeUserAction");
      // console.log("ActiveType: " + activeType);
      switch (activeType) {
        case "f_groups":
          switch (user.userType) {
            case 1: 
              setFollowDetails({
                headerText: "Groups subscribed to you:",
                action: "subscribe",
                emptyText1: "There are no Groups subscribed to you yet.",
                emptyText2: "Try creating some content with the Create Item or Create Datum tabs.  Be sure to tag Themes to help Groups find your content easier.  Check out some of the recently stamped data for a few Groups in your Browse Groups feed for some guidance."
              });
              // console.log("Inside f_groups switch. Action: " + followDetails.action);
              break;
            case 0:
              setFollowDetails({
                headerText: "Groups you are following:",
                action: "follow",
                emptyText1: "You are not following any Groups yet.",
                emptyText2: "Head over to the Browse Groups tab and follow Groups whose values align with yours."
              });
              break;
          } 
        break;
        case "f_authors":
          setFollowDetails({
            headerText: "Authors you are subscribed to:",
            action: "subscribe",
            emptyText1: "You are not subscribed to any Authors yet.",
            emptyText2: "Head over to the Browse Authors tab and subscribe to some Authors whose content you would like to watch closely."
          });
          // console.log(followDetails);
          // console.log(followDetails.action);
          break;
        case "f_general":
          setFollowDetails({
            headerText: "Users following you:",
            action: "follow",
            emptyText1: "There aren't any Users following you yet.",
            emptyText2: "Make sure that your profile is complete, you have values set and you've explained your stance on them, and you are stamping data to bring it to potential followers.  All of these things go a long way to get Users' attention!"
          });
          break;
      }
    }
    setRelativeUserAction();

  }, [activeType]) 


  return (
    <>
      <div className="ProfileSide">
          <ItemSearch />
          <ProfileCard id="anchor_profile"/>
      
          {(userType == 2) 
            ? 
              <FollowBar
                activeType = {activeType}
                typeSelector = {typeSelector}
              /> 
            : ""
          }
          {/* <UserSearch
            activeType = {activeType}
          /> */}
          <FollowCard
            activeType = {activeType}
            followDetails = {followDetails}
          />
       </div>
    </>
  )
}

export default ProfileSide