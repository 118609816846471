import * as UserApi from '../api/UserRequest'
import * as ItemApi from '../api/ItemManipulation'
import * as LogsApi from '../api/LogsRequest'


export const loadModal = (data, logDetails) => async(dispatch) => {
    dispatch({type: "MODAL_LOAD", modalPackage: data})
    await LogsApi.sendLog(JSON.stringify(logDetails));

}

export const fetchModalUser = (id, logDetails) => async(dispatch) => {
    dispatch({type: "MODAL_FETCH_USER_START"});
    try {
        const {data} = await UserApi.getUser(id, JSON.stringify(logDetails));
        console.log(data);
        dispatch({type: "MODAL_FETCH_USER_SUCCESS", data: data});
    } catch (error) {
        dispatch({type: "MODAL_FETCH_USER_FAIL"})
        console.log(error);
    }    
}

export const fetchModalItem = (itemId, userId, logDetails) => async(dispatch) => {
    dispatch({type: "MODAL_FETCH_ITEM_START"});
    try {
        const {data} = await ItemApi.getItem(itemId, userId, JSON.stringify(logDetails));
        dispatch({type: "MODAL_FETCH_ITEM_SUCCESS", data: data});
    } catch (error) {
        dispatch({type: "MODAL_FETCH_ITEM_FAIL"})
        console.log(error);
    }    
}

export const closeModalAction = () => async(dispatch) => {
    dispatch({type: "MODAL_CLOSE"})
}